main {
    position: relative;
    transform: translateZ(1px);
}

section {
    position: relative;
    // @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit);

    // &:first-of-type {
    // 	@include fluid-paddingTop($from-small, $to-enormous, $unit-small*2, $unit*2);
    // }

    // &:last-of-type {
    // 	@include fluid-paddingBottom($from-small, $to-enormous, $unit-small*2, $unit*2);
    // }
}

.o-section-reasons {
    color: $c-white;

    h1,
    h2,
    h3,
    h4 {
        color: $c-white;
    }

    a {
        transition: color $speed $easing;

        &:hover {
            color: $c-quaternary;
        }
    }
}

.o-section {
    @include media("<md") {
        @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit);
    }
}

.o-section_blog {
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit*2);
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit*2/4);

    h1 {
        margin-bottom: 0;
    }

    @include media("<md") {
        @include fluid-paddingTop($from-small, $to-enormous, $unit-small + 64px, $unit*2 + 64px);
    }
}

.o-section_blog-featured {
    padding-left: 0;
    padding-right: 0;

    .c-featured-post_wrap {
        position: relative;

        &:hover {
            .o-ratio {
                .c-bg-img {
                    transform: scale(1.05);
                }
            }
        }

        &.-video {
            .o-ratio {
                cursor: pointer;

                .c-post_play-btn {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    .c-post_play-svg-wrapper {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        color: #fff;
                        @include fluid-width($from-small, $to-enormous, 60px, 100px);
                        @include fluid-height($from-small, $to-enormous, 60px, 100px);

                        &:hover {
                            .stroke {
                                top: -8px;
                                bottom: -8px;
                                left: -8px;
                                right: -8px;
                            }
                        }

                        svg {
                            position: absolute;
                            width: 44px;
                            height: 44px;
                            top: 50%;
                            left: calc(50% + 4px);
                            -webkit-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                        }

                        .stroke {
                            border: solid 4px #fff;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            border-radius: 100%;
                            transition: all $speed $easing;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .o-ratio {
            @include media("<sm") {
                &::before {
                    padding-bottom: 100%;
                }
            }

            a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .c-bg-img {
                transform: scale(1.01);
                transition: transform $speed*3 $easing;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba($c-secondary, 0.75);
                }
            }
        }

        .c-featured-post_category {
            @include fluid-type($from-small, $to-enormous, 11px, 13px);
            color: $white;
            text-transform: uppercase;
            pointer-events: none;

            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);

            span {
                position: relative;
                display: inline-block;
                margin: 0;
                line-height: 1;
                pointer-events: none;

                &:not(:last-child) {
                    padding-right: 35px;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        background-color: lighten($c-secondary, 30%);
                        right: 15px;
                        margin-top: -10px;
                        margin-bottom: -10px;
                        height: 36px;
                    }
                }

                svg {
                    width: 12px;
                    height: 12px;
                    margin-top: -2px;
                    margin-right: 2px;
                    line-height: 1;
                }
            }
        }

        .o-text {
            position: absolute;
            @include fluid-bottom($from-small, $to-enormous, $unit-small/2, $unit-small);
            @include fluid-left($from-small, $to-enormous, $unit-small/2, $unit-small);
            @include fluid-right($from-small, $to-enormous, $unit-small/2, $unit-small);
            pointer-events: none;

            a {
                color: $white;
                transition: color $speed $easing;

                &:hover {
                    color: darken($white, 10%);
                }
            }

            h2 {
                display: inline-block;
                pointer-events: all;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                color: $white;
            }

            .c-post_info {
                text-transform: uppercase;
                @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
                color: $white;

                a {
                    pointer-events: all;
                }

                .c-post_date {
                    @include fluid-type($from-small, $to-enormous, 11px, 13px);
                }

                .c-post_author {
                    display: inline-block;
                    @include fluid-type($from-small, $to-enormous, 11px, 13px);

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-bottom: 2.5px;
                    }
                }
            }

            .button {
                @include fluid-type($from-small, $to-enormous, 12px, 16px);
                padding: 1.4rem 6.2rem 1.4rem 2.8rem;
                pointer-events: all;

                .icon {
                    right: 2.8rem;
                }

                .stroke {
                    border: solid 0.2rem $c-quaternary;
                    background-color: $c-quaternary;
                }

                .icon,
                .txt {
                    color: $c-secondary;
                }

                .overlay1 {
                    background-color: $c-quaternary;
                }

                .overlay2 {
                    background-color: $c-tertiary;
                }
            }
        }
    }

    .c-share-overlay {
        position: absolute;
        @include fluid-top($from-small, $to-enormous, $unit-small/2, $unit-small);
        @include fluid-left($from-small, $to-enormous, $unit-small/2, $unit-small);

        .c-share-title {
            color: $white;
            text-transform: uppercase;
            @include fluid-type($from-small, $to-enormous, 10px, 12px);
            margin-bottom: 15px;
            position: relative;

            svg {
                width: 12px;
                height: 12px;
                margin-top: -2px;
                margin-right: 5px;
            }
        }

        .c-share_wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .c-share-link {
                &:first-child {
                    margin-left: -8px;
                }
            }

            a {
                position: relative;
                display: block;
                color: $white;
                @include fluid-paddingX($from-small, $to-enormous, 8px, 8px);

                &:hover {
                    svg {
                        transform: scale(0.8);
                    }
                }

                svg {
                    width: 18px;
                    height: 18px;
                    transition: transform $speed $easing;
                }
            }
        }
    }
}

.o-section_blog_all-posts-title {
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit*2);
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit*2/4);

    h2 {
        margin-bottom: 0;
    }
}

.results-loader_spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    border: solid 2px #F3D33C;
    border-top: solid 2px transparent;
    border-radius: 200px;
    animation: rotating 1s linear infinite;
    z-index: 3;

    opacity: 0;
    visibility: hidden;

    transition: opacity $speed $easing, visibility $speed $easing;

    &.loading {
        opacity: 1;
        visibility: visible;
        transition: opacity 0s linear, visibility 0s linear;
    }
}

.o-section_blog_all-posts {
    position: relative;
    // @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/1.5, $unit*2/1.5);
    // @include fluid-paddingY($from-small, $to-enormous, $unit-small/2, $unit-small);

    &.-padding-top {
        @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit*2);
    }

    &.-padding-bottom {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small - $unit-small/2, $unit*2 - $unit);
    }

    &.-padding-bottom-pagination {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit*2);
    }

    .o-pagination_wrap {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small - $unit-small/2, $unit*2 - $unit);
    }

    .results-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-light-grey;
        z-index: 3;

        opacity: 0;
        visibility: hidden;

        transition: opacity $speed $easing, visibility $speed $easing;

        &.loading {
            opacity: 1;
            visibility: visible;
            transition: opacity 0s linear, visibility 0s linear;
        }
    }

    .c_blog_post {
        // @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2*2, $unit-small*2);
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit);
    }

    .c-post_wrap {
        position: relative;
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

        &.-video {
            .o-ratio {
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba($black, 0.25);
                }

                .c-post_play-btn {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;

                    .c-post_play-svg-wrapper {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        color: #fff;
                        @include fluid-width($from-small, $to-enormous, 60px, 100px);
                        @include fluid-height($from-small, $to-enormous, 60px, 100px);

                        &:hover {
                            .stroke {
                                top: -8px;
                                bottom: -8px;
                                left: -8px;
                                right: -8px;
                            }
                        }

                        svg {
                            position: absolute;
                            width: 44px;
                            height: 44px;
                            top: 50%;
                            left: calc(50% + 4px);
                            -webkit-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                        }

                        .stroke {
                            border: solid 4px #fff;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            border-radius: 100%;
                            transition: all $speed $easing;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .o-ratio {

            &:hover {
                .c-bg-img {
                    transform: scale(1.05);
                }
            }

            .c-bg-img {
                transform: scale(1.01);
                transition: transform $speed*3 $easing;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(0deg, rgba($black, 0.6) 0%, rgba($black, 0) 50%);

                }
            }

            a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .c-post_category {
            position: absolute;
            bottom: 10px;
            left: 10px;
            // background-color: $c-secondary;
            @include fluid-type($from-small, $to-enormous, 11px, 13px);
            line-height: 1;
            color: $white;
            text-transform: uppercase;
            pointer-events: none;
            width: calc(100% - 48px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            svg {
                width: 12px;
                height: 12px;
                margin-top: -2px;
                margin-right: 2px;
            }
        }

        .c-share-button {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 10px;

            cursor: pointer;

            z-index: 4;

            &.-show {
                svg {
                    color: $c-secondary;
                }
            }

            &:hover {
                svg {
                    transform: scale(0.8);
                }
            }

            svg {
                width: 18px;
                height: 18px;
                color: $white;
                transition: transform $speed $easing, color $speed $easing;
            }
        }

        .c-share-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($white, 0.95);
            z-index: 3;

            opacity: 0;
            visibility: hidden;
            user-select: none;

            transition: opacity $speed $easing, visibility $speed $easing;

            &.-show {
                opacity: 1;
                visibility: visible;

                .c-share__wrapper {
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            .c-share__wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0.8);
                transition: transform $speed $easing;
            }

            .c-share-title {
                color: $c-secondary;
                text-transform: uppercase;
                @include fluid-type($from-small, $to-enormous, 10px, 12px);
                text-align: center;
                margin-bottom: 15px;
            }

            .c-share_wrap {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .c-share-link {
                position: relative;
            }

            a {
                position: relative;
                display: block;
                color: $c-secondary;
                @include fluid-paddingX($from-small, $to-enormous, 8px, 8px);

                &:hover {
                    svg {
                        transform: scale(0.8);
                    }
                }

                svg {
                    width: 18px;
                    height: 18px;
                    transition: transform $speed $easing;
                }
            }
        }
    }

    .c-post_info {
        text-transform: uppercase;
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

        .c-post_date {
            @include fluid-type($from-small, $to-enormous, 11px, 13px);
        }

        .c-post_author {
            display: inline-block;
            @include fluid-type($from-small, $to-enormous, 11px, 13px);

            svg {
                width: 16px;
                height: 16px;
                margin-bottom: 2.5px;
            }
        }
    }

    .o-text {

        h2 {
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
        }

        a {
            color: $c-secondary;

            transition: color $speed $easing;

            &:hover {
                color: darken($c-secondary, 10%);
            }
        }

        .button {
            @include fluid-type($from-small, $to-enormous, 12px, 16px);
            padding: 1.4rem 6.2rem 1.4rem 2.8rem;

            .icon {
                right: 2.8rem;
            }

            .stroke {
                border: solid 0.2rem $c-quaternary;
                background-color: $c-quaternary;
            }

            .icon,
            .txt {
                color: $c-secondary;
            }

            .overlay1 {
                background-color: $c-quaternary;
            }

            .overlay2 {
                background-color: $c-tertiary;
            }
        }
    }

    // .o-pagination-list {
    //     @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit*2);
    // }
}

.o-section_folder {
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small/1.5, $unit*2/1.5);
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit*2/4);

    h1 {
        margin-bottom: 0;
    }

    @include media("<md") {
        @include fluid-paddingTop($from-small, $to-enormous, $unit-small + 64px, $unit*2 + 64px);
    }
}

.o-section_folder-description {
    padding: 0;
    // @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit*2);

    @include media("<md") {
        margin-top: 64px;
    }

    .c-description_wrap {
        position: relative;

        .o-ratio {
            @include media("<sm") {
                &::before {
                    padding-bottom: 75%;
                }
            }

            .c-bg-img {
                transform: scale(1.01);
                transition: transform $speed*3 $easing;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba($c-secondary, 0.75);
                }
            }
        }

        .o-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            width: 70%;
            @include fluid-padding($from-small, $to-enormous, $unit-small/2, $unit);

            @include media("<md") {
                width: 100%;
            }

            h1 {
                color: $white;
            }

            p {
                @include fluid-type($from-small, $to-enormous, 16px, 28px);
            }
        }
    }
}

.o-section-get-more,
.o-section-upcoming-events {
    position: relative;
    @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit*2);

    @include media("<md") {
        @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit);

        .-dossier {
            &:not(:first-child) {
                @include fluid-marginTop($from-small, $to-enormous, $unit-small, $unit*2);
            }
        }
    }

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-tertiary;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .o-ratio {
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

        &:hover {
            .c-bg-img {
                transform: scale(1.05);
            }
        }

        .c-bg-img {
            transform: scale(1.01);
            transition: transform $speed*3 $easing;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, rgba($black, 0.6) 0%, rgba($black, 0) 50%);

            }
        }

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .-title {
        border-bottom: 1px solid $white;
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2 + 10px, $unit-small + 10px);

        h2 {
            color: $white;
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
        }
    }

    .o-text {
        h3 {
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
        }

        a {
            color: $black;
            transition: color $speed $easing;

            &:hover {
                color: $c-secondary;
            }
        }

        .button {
            @include fluid-type($from-small, $to-enormous, 12px, 16px);
            padding: 1.4rem 6.2rem 1.4rem 2.8rem;

            .icon {
                right: 2.8rem;
            }

            .stroke {
                border: solid 0.2rem $c-secondary;
                background-color: $c-secondary;
            }

            .icon,
            .txt {
                color: $white;
            }

            .overlay1 {
                background-color: $c-secondary;
            }

            .overlay2 {
                background-color: lighten($c-tertiary, 5%);
            }
        }

        .c-post_info {
            text-transform: uppercase;
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

            .c-post_date {
                @include fluid-type($from-small, $to-enormous, 11px, 13px);
            }

            .c-post_author {
                display: inline-block;
                @include fluid-type($from-small, $to-enormous, 11px, 13px);

                svg {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 2.5px;
                }
            }
        }
    }
}

.o-section-typeform {
    position: relative;
    @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit*2);

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-tertiary;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .o-text {
        text-align: center;
        @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit-small);

        .button {
            @include fluid-type($from-small, $to-enormous, 12px, 16px);
            padding: 1.4rem 6.2rem 1.4rem 2.8rem;

            .icon {
                right: 2.8rem;
            }

            .stroke {
                border: solid 0.2rem $c-secondary;
                background-color: $c-secondary;
            }

            .icon,
            .txt {
                color: $white;
            }

            .overlay1 {
                background-color: $c-secondary;
            }

            .overlay2 {
                background-color: $c-tertiary;
            }
        }
    }
}

.cta-category {
    text-transform: uppercase;
    color: $c-secondary;
    @include fluid-type($from-small, $to-enormous, 12px, 16px);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 10px;
        width: 48px;
        height: 48px;
    }

    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/4, $unit*2/4);
}

.o-section-recommended {
    position: relative;

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-tertiary;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .o-text {
        @include fluid-paddingRight($from-small, $to-enormous, $unit-small/2, $unit-small);
        @include fluid-paddingY($from-small, $to-enormous, $unit-small/2, $unit-small);

        @include media("<md") {
            @include fluid-paddingY($from-small, $to-enormous, $unit-small/2, $unit);
            @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit);
        }

        .button {
            @include fluid-type($from-small, $to-enormous, 12px, 16px);
            padding: 1.4rem 6.2rem 1.4rem 2.8rem;

            .icon {
                right: 2.8rem;
            }

            .stroke {
                border: solid 0.2rem $c-secondary;
                background-color: $c-secondary;
            }

            .icon,
            .txt {
                color: $white;
            }

            .overlay1 {
                background-color: $c-secondary;
            }

            .overlay2 {
                background-color: $c-tertiary;
            }
        }
    }
}

.o-section-citation {
    position: relative;
    @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit*2);
    color: $white;

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-black;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .o-text {
        position: relative;

        &.-citation {
            p {
                @include fluid-type($from-small, $to-enormous, 16px, 28px);
            }

            .-citation_wrap {
                position: relative;

                p {
                    margin-bottom: 0;
                }

                svg {
                    @include fluid-marginY($from-small, $to-enormous, $unit-small/2/2, $unit*2/2/2);
                    color: $c-primary;
                    width: 24px;
                    height: 24px;
                }
            }

            .-citee {
                @include fluid-marginTop($from-small, $to-enormous, $unit-small/2/2, $unit*2/2/2);
                @include fluid-type($from-small, $to-enormous, 16px, 18px);
                margin-bottom: 0;
            }

            .-citee-title {
                @include fluid-type($from-small, $to-enormous, 12px, 14px);
                margin-bottom: 0;
            }
        }
    }
}

.o-section-stats {
    position: relative;
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit*2);
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit*2/2);
    color: $white;

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-black;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .-title {
        border-bottom: 1px solid $white;
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2 + 10px, $unit-small + 10px);

        h2 {
            color: $white;
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
        }
    }

    .o-text {
        h2 {
            color: $white;
        }

        .-number_wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);

            .-pre {
                @include fluid-type($from-small, $to-enormous, 24px, 32px);
                margin-right: 10px;
                color: $c-primary;
            }

            .-suf {
                @include fluid-type($from-small, $to-enormous, 24px, 32px);
                margin-left: 10px;
                color: $c-primary;
            }

            .-number {
                @include fluid-type($from-small, $to-enormous, 64px, 72px);
            }
        }
    }

    .-stat_wrap {
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit*2/2);
    }
}

.o-section-ressources {
    position: relative;
    @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit*2);
    color: $white;

    .c-bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 8.333333%;
        right: 8.333333%;
        background-color: $c-black;

        @include media("<md") {
            left: 0;
            right: 0;
        }
    }

    .-title {
        border-bottom: 1px solid $white;
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2 + 10px/4, $unit-small + 10px/4);

        h2 {
            color: $white;
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
        }
    }

    .o-text {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }
    }

    .-ressource {
        position: relative;
        @include fluid-paddingTop($from-small, $to-enormous, $unit-small/2 + 10px/4, $unit-small + 10px/4);

        @include media("<sm") {
            padding-top: 0;
        }

        &:nth-of-type(2) {
            @include fluid-marginTop($from-small, $to-enormous, -$unit-small/2 - 10px/4, -$unit-small - 10px/4);
        }

        &:hover {
            .c-bg-color_inner {
                bottom: 2px;
            }

            .o-layout_item {
                transform: translateX($unit-small);

                @include media("<sm") {
                    transform: none;
                }
            }

            .icon {
                transform: translateX(-$unit-small);

                @include media("<sm") {
                    transform: none;
                }
            }
        }

        .o-layout_item {
            transition: transform $speed $easing;
        }

        .c-bg-color_inner {
            position: absolute;
            top: 2px;
            bottom: 100%;
            @include fluid-left($from-small, $to-enormous, $unit-small/2, $unit-small);
            right: 0;
            background-color: darken($c-black, 2.5%);

            transition: bottom $speed $easing;
        }

        .o-ratio {
            @include media("<sm") {
                margin-bottom: $unit-small/2;
            }
        }

        .-line {
            @include fluid-marginTop($from-small, $to-enormous, $unit-small/2 + 10px/4, $unit-small + 10px/4);
            border-bottom: 1px solid $white;
            @include fluid-marginLeft($from-small, $to-enormous, $unit-small/2, $unit-small);
        }

        .icon {
            position: absolute;
            @include fluid-top($from-small, $to-enormous, $unit-small/2 + 10px/4, $unit-small + 10px/4);
            right: 0;
            width: 24px;
            height: 24px;

            transition: transform $speed $easing;

            @include media("<sm") {
                top: $unit-small/2;
                right: $unit-small/2;
            }
        }

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            @include fluid-left($from-small, $to-enormous, $unit-small/2, $unit-small);
            right: 0;
        }
    }
}

.o-section-authors {
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small - $unit-small/2, $unit*2 - $unit-small);

    .c-author_wrap {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        @include fluid-padding($from-small, $to-enormous, $unit-small/2, $unit-small);
        @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

        @include media("<sm") {
            flex-wrap: wrap
        }

        .o-img {
            @include fluid-width($from-small, $to-enormous, 200px, 400px);
            height: auto;

            @include media("<sm") {
                @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
            }
        }

        .o-text {
            width: 100%;
            @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit-small);

            @include media("<sm") {
                width: 100%;
                padding: 0;
            }

            p {
                @include fluid-marginBottom($from-small, $to-enormous, 20px, 20px);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.event-img-section {
    img {
        width: 100%;
    }
}

.o-section-infographic {
    .c-img_wrap {
        position: relative;

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    img {
        width: 100%;
    }
}

.-single-blogue {

    .-padding {
        @include fluid-paddingY($from-small, $to-enormous, $unit-small, $unit*2);
    }

    .c-breadcrumb {
        @include fluid-marginBottom($from-small, $to-enormous, $unit, $unit);

        @include media("<medium") {
            padding-top: 80px;
        }

        a {
            color: $c-primary;

            transition: color $speed $easing;

            &:hover {
                color: $c-secondary;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            margin-right: 5px;
            color: $c-secondary;
        }
    }

    .date {
        margin-bottom: 0;
    }

    .c-author_wrap {
        display: flex;
        align-items: center;
        @include fluid-marginTop($from-small, $to-enormous, $unit, $unit);

        .o-ratio {
            width: 75px;
            border-radius: 1000px;
            margin-right: 20px;
        }

        .o-text {
            .-by {
                @include fluid-type($from-small, $to-enormous, 11px, 13px);
                margin-bottom: 0;
            }

            .-name {
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .-more {
                color: $c-secondary;
                transition: color $speed $easing;
                text-decoration: none;

                &:hover {
                    color: darken($c-secondary, 10%);
                }
            }
        }
    }
}

.o-section-listing {
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit);

    .-number {
        position: absolute;
        @include fluid-type($from-small, $to-enormous, 14px, 18px);
        background-color: $c-primary;
        color: $white;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .-table-of-content {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit);

        li {
            padding-top: 20px;
            margin-bottom: 0;
        }

        a {
            display: inline-block;
            @include fluid-type($from-small, $to-enormous, 16px, 20px);
            margin-top: 10px;
            margin-bottom: 20px;
            padding-left: 60px;
            color: $c-primary;
        }
    }

    .-listing {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit);

        .-number {
            background-color: transparent;
            color: $c-primary;
            border-bottom: 1px solid $c-primary;
        }

        h2 {
            display: inline-block;
            padding-left: 60px;
        }

        ul {
            @include fluid-type($from-small, $to-enormous, $min-font-size-p, $max-font-size-p);
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

            li {
                @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
                position: relative;
                padding-left: $unit-small/2;

                &::before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 12px;
                    height: 1px;
                    background-color: $c-secondary;
                }
            }
        }

        img {
            max-width: 100%;
        }
    }
}

.o-section-text {
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small, $unit);

    .-text {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit);

        h1, h2, h3, h4, h5, h6, p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        h2 {
            display: inline-block;
        }

        ul {
            @include fluid-type($from-small, $to-enormous, $min-font-size-p, $max-font-size-p);
            @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

            li {
                @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
                position: relative;
                padding-left: $unit-small/2;

                &::before {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 12px;
                    height: 1px;
                    background-color: $c-secondary;
                }

                a {
                    color: $c-primary;

                    &:hover {
                        color: lighten($c-primary, 10%);
                    }
                }
            }
        }
    }
}

/*
 * Default WP Alignment Classes
 *****************************************************************************/

.o-section-single {
    .o-text {
        &::after {
            content: "";
            clear: both;
            display: table;
        }

        ul {
            @include fluid-marginLeft($from-small, $to-enormous, $unit-small, $unit-small);
        }

        ol {
            @include fluid-marginLeft($from-small, $to-enormous, $unit-small, $unit-small);
            @include fluid-paddingLeft($from-small, $to-enormous, $unit-small/2, $unit-small/2);

            li {
                position: relative;
                @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2/2, $unit-small/2);
                list-style-type: decimal;

                &:last-child {
                    margin-bottom: 0;
                }

                &::marker {
                    color: red;
                    font-weight: $bold;
                }
            }

            @include media(">md") {
                margin-bottom: rem(30px);
            }

            @include media("<md") {
                margin-bottom: 30px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.aligncenter,
.alignleft,
.alignright {
    display: block;
    padding: 0;
}

.aligncenter {
    float: none;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(30px);

    @include media("<md") {
        margin-bottom: 30px;
    }
}

.alignright {
    float: right;
    margin-top: 4px;
    margin-bottom: rem(30px);
    margin-left: rem(30px);

    @include media("<md") {
        margin-bottom: 30px;
        margin-left: 30px;
    }
}

.alignleft {
    float: left;
    margin-top: 4px;
    margin-bottom: rem(30px);
    margin-right: rem(30px);

    @include media("<md") {
        margin-bottom: 30px;
        margin-right: 30px;
    }
}

.wp-caption {
    padding: 5px 0;
    background: $c-secondary;
    text-align: center;
}

.wp-caption img {
    display: inline;
}

.wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 11px;
    font-weight: 100;
    font-style: italic;
    color: $black;
}

blockquote {
    position: relative;
    padding-left: $unit-small;
    border-left: 4px solid $c-secondary;
    @include fluid-type($from-small, $to-enormous, 18px, 26px);
    margin: $unit-small;
    padding-bottom: $unit-small;

    &:before,
    &:after {
        content: '«';
        font-family: $font-alt;
        color: $c-secondary;
        @include fluid-type($from-small, $to-enormous, 36px, 52px);
    }

    &:after {
        content: '»';
    }

    p {
        @include fluid-type($from-small, $to-enormous, 18px, 26px);
    }

    cite {
        position: absolute;
        bottom: 0;
        left: $unit-small;
        display: inline-block;
        @include fluid-type($from-small, $to-enormous, $min-font-size-p, $max-font-size-p);
    }
}

.o-section-share {
    position: relative;
    @include fluid-paddingTop($from-small, $to-enormous, $unit-small/2, $unit*2/2);
    @include fluid-paddingBottom($from-small, $to-enormous, $unit-small, $unit*2);

    .-line {
        position: absolute;
        top: 0;
        left: 8.333333%;
        right: 8.333333%;
        border-top: 1px solid $c-secondary;
    }

    .c-share-overlay {
        @include fluid-paddingBottom($from-small, $to-enormous, $unit-small/2, $unit*2/2);

        .c-share-title {
            color: $c-secondary;
            text-transform: uppercase;
            @include fluid-type($from-small, $to-enormous, 10px, 12px);
            margin-bottom: 15px;
            position: relative;

            svg {
                width: 12px;
                height: 12px;
                margin-top: -2px;
                margin-right: 5px;
            }
        }

        .c-share_wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .c-share-link {
                &:first-child {
                    margin-left: -8px;
                }
            }

            a {
                position: relative;
                display: block;
                color: $c-secondary;
                @include fluid-paddingX($from-small, $to-enormous, 8px, 8px);

                &:hover {
                    svg {
                        transform: scale(0.8);
                    }
                }

                svg {
                    width: 18px;
                    height: 18px;
                    transition: transform $speed $easing;
                }
            }
        }
    }
}

p {
    iframe {
        max-width: 100%;
    }
}

// Woocommerce

figure {
    margin: 0 !important;
}

.div-160 {
    width: 100% !important;
}

.onsale {
    background-color: #D04F42 !important;
    color: #ffffff !important;
    padding: 0 !important;
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    left: unset !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
    z-index: 2 !important;
    font-size: 10px !important;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_title {
    margin-bottom: 32px !important;
}

.woocommerce-product-details__short-description {
    margin-bottom: 32px !important;
}

.product_meta {
    font-size: 14px !important;
}

/* WooCommerce Gallerie */
.woocommerce-product-gallery__image {
    position: relative !important;
    display: inline-block !important;
    margin-bottom: 32px !important;
    margin-right: 0 !important;
}

.woocommerce-product-gallery__image:first-child {
    margin-right: 0 !important;
}

.woocommerce-product-gallery__image a {
    display: -webkit-box !important;
    display: flex !important;
}

.woocommerce-product-gallery__image img {
    display: inline-block !important;
}

.flex-control-nav {
    display: -webkit-box !important;
    display: flex !important;
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 16px !important;
    margin-left: -16px !important;
}

.flex-control-nav li {
    margin: 16px !important;
    cursor: pointer !important;
}

.flex-control-nav li:hover {
    cursor: pointer !important;
}

.woocommerce-product-gallery__trigger {
    position: absolute !important;
    z-index: 2 !important;
    top: 0 !important;
    left: 0 !important;
    padding: 18px 16px 16px 16px !important;
    background-color: #ffffff !important;
}

/* Add to cart button */
.cart {
    margin-bottom: 32px !important;
}

.cart input[type="number"] {
    border: solid 0.2rem #59A9A5 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
}

.cart input[type="number"]:focus {
    border: solid 0.2rem #D04F42 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1 !important;
}

.cart button {
    // background-color: transparent !important;
    // border: none !important;
    cursor: pointer !important;
}

.cart .button .txt {
    color: #D04F42 !important;
}

.cart .button .icon {
    color: #D04F42 !important;
}

/* Price tag */
.price ins {
    text-decoration: none !important
}

.price del {
    font-size: 18px !important;
}

.price {
    background-color: #D04F42 !important;
    padding: 16px 32px !important;
    display: inline-block !important;
    color: #ffffff !important;
    font-size: 24px !important;
    font-weight: 800 !important;
    margin-bottom: 32px !important;
}

/* Stock tag */
.stock {
    text-transform: uppercase !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    letter-spacing: 2px !important;
    margin-bottom: 32px !important;
}

/* Galery lightbox */
.pswp {
    z-index: 100000000 !important;
}

/* Product columns */
@media (max-width: 999px) {

    .product .column,
    .product .columns {
        padding: 6.4rem !important;
    }
}

@media (max-width: 549px) {

    .product .column,
    .product .columns {
        padding: 3.2rem !important;
    }
}

/* Shop menu */
.shop-menu {
    font-size: 0 !important;
    background-color: #F3D33C !important;
}

@media (max-width: 999px) {
    .shop-menu {
        margin-top: 64px !important;
    }
}

.shop-menu a {
    color: #231f20 !important;
    display: inline-block !important;
    padding: 24px 32px !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    -webkit-transition: all ease 300ms !important;
    transition: all ease 300ms !important;
}

.shop-menu a:hover {
    color: #59A9A5 !important;
}

.shop-menu span {
    color: #59A9A5 !important;
    font-size: 16px !important;
    pointer-events: none !important;
}

button:focus {
    outline: none !important;
}

/* Add to cart message */
.wc-forward {
    padding: 0 !important;
    color: #231f20 !important;
}

.woocommerce-notices-wrapper li {
    margin-bottom: 2px !important;
}

.woocommerce-message {
    border-top-color: #F3D33C !important;
}

.woocommerce-message::before {
    color: #F3D33C !important;
}

.woocommerce-error {
    border-top-color: #D04F42 !important;
}

.woocommerce-error::before {
    color: #D04F42 !important;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
    padding: 24px 64px 24px 96px !important;
    color: #231f20 !important;
}

.woocommerce-error::before,
.woocommerce-info::before,
.woocommerce-message::before {
    top: 23px !important;
    left: 64px !important;
}

/* Cart page */
.woocommerce table.shop_table {
    border-radius: 0 !important;
    border: 0 !important;
    border-collapse: collapse !important;
}

.woocommerce table.shop_table td,
.woocommerce table.shop_table th {
    border-bottom: solid 0.2rem #f7f6f7 !important;
    border-top: solid 0.2rem #f7f6f7 !important;
    padding: 20px !important;
}

.woocommerce table.shop_table th {
    background-color: #f7f6f7 !important;
}

.woocommerce table.shop_table th.product-remove,
.woocommerce table.shop_table th.product-thumbnail {
    background-color: transparent !important;
    border-top: 0 !important;
}

.woocommerce a.remove {
    margin: auto !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 1.3 !important;
    -webkit-transition: all ease 300ms !important;
    transition: all ease 300ms !important;
}

//   .woocommerce a.remove:hover {}

.product-thumbnail a {
    display: -webkit-box !important;
    display: flex !important;
}

.woocommerce-cart-form__cart-item .product-thumbnail {
    max-width: 70px !important;
}

/* Checkout button */
.checkout-button {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    padding: 1.8rem 7.2rem 1.8rem 3.2rem !important;
    letter-spacing: 0.08rem !important;
    font-weight: 700 !important;
}

.checkout-button .txt {
    color: #D04F42 !important;
}

.checkout-button .icon {
    color: #D04F42 !important;
}

/* Coupon code */
.woocommerce table.cart td.actions .input-text {
    border: solid 0.2rem #59A9A5 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    width: 160px !important;
    margin: 0 -6px 0 0 !important;
    font-size: 14px !important;
    height: auto !important;
    padding: 15px 32px !important;
}

/* Buttons in general */
.coupon-button {
    border-radius: 0 !important;
    border: solid 0.2rem #59A9A5 !important;
    padding: 9px 18px !important;
    background-color: #59A9A5 !important;
    color: #000000 !important;
    font-size: 14px !important;
    height: auto !important;
    // margin-top: 1px !important;
    -webkit-transition: all ease 300ms !important;
    transition: all ease 300ms !important;
}

.coupon-button:hover {
    border: solid 0.2rem #59A9A5 !important;
    padding: 9px 18px !important;
    background-color: #F3D33C !important;
    color: #000000 !important;
    font-size: 14px !important;
    height: auto !important;
    // margin-top: 1px !important;
}

.update-cart-button {
    border-radius: 0 !important;
    border: solid 0.2rem #F3D33C !important;
    padding: 9px 18px !important;
    background-color: #F3D33C !important;
    color: #231f20 !important;
    font-size: 14px !important;
    height: auto !important;
    margin-top: 1px !important;
    -webkit-transition: all ease 300ms !important;
    transition: all ease 300ms !important;
}

.product-name a {
    color: #59A9A5 !important;
}

.return-to-shop .button {
    border-radius: 0 !important;
    border: solid 0.2rem #59A9A5 !important;
    padding: 9px 18px !important;
    background-color: #59A9A5 !important;
    color: #ffffff !important;
    font-size: 14px !important;
    margin-top: 1px !important;
    -webkit-transition: all ease 300ms !important;
    transition: all ease 300ms !important;
}

.woocommerce-info::before {
    color: #59A9A5 !important;
}

.woocommerce-info {
    border-top-color: #59A9A5 !important;
}

.woocommerce-checkout #payment {
    background: #eeeeee !important;
}

/* Place order button */
.woocommerce .place-order button.button {
    background-color: #F3D33C !important;
    border: none !important;
    cursor: pointer;
    padding: 1.8rem 7.2rem 1.8rem 3.2rem !important;
}

.place-order .button .txt {
    color: #231f20 !important;
}

.place-order .button .icon {
    color: #231f20 !important;
}

.place-order .button .stroke {
    border: solid 0.2rem #F3D33C !important;
}

//   .place-order .button .icon svg {}

.place-order .button {
    display: inline-block !important;
    position: relative !important;
    padding: 1.8rem 7.2rem 1.8rem 3.2rem !important;
    letter-spacing: 0.08rem !important;
    font-weight: 700 !important;
}

.products .product {
    padding: 20px !important;
}

.products .product img {
    width: 100% !important;
    margin-bottom: 32px !important;
}

.products .product .onsale {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}

.products .product .woocommerce-loop-product__title {
    font-size: 24px !important;
    margin-bottom: 32px !important;
    color: #59A9A5 !important;
}

.products .product .price {
    position: absolute !important;
    top: 32px !important;
    left: 32px !important;
    font-size: 14px !important;
    padding: 10px 24px !important;
    background-color: #ffffff !important;
    color: #D04F42 !important;
}

.products .product .price del {
    font-size: 12px !important;
}

/**/

.simple-add-to-cart-button .txt {
    color: #D04F42 !important;
}

.simple-add-to-cart-button .icon {
    color: #D04F42 !important;
}

.added_to_cart {
    display: inline-block;
    padding: 32px 0 !important;
}

/**/

.woocommerce-MyAccount-navigation {
    width: 29% !important;
    z-index: 2 !important;
    pointer-events: all !important;
    display: inline-block !important;
    position: relative !important;
    vertical-align: top !important;
}

.woocommerce-MyAccount-content {
    width: 70% !important;
    display: inline-block !important;
    position: relative !important;
}

@media (max-width: 999px) {
    .woocommerce-MyAccount-navigation {
        width: calc(100% - 64px) !important;
        position: relative !important;
        padding: 32px !important;
    }

    .woocommerce-MyAccount-content {
        width: calc(100% - 64px) !important;
        padding: 32px !important;
    }
}

.woocommerce-MyAccount-navigation ul {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.woocommerce-MyAccount-navigation ul li {
    margin-bottom: 0 !important;
}

.woocommerce-MyAccount-navigation ul li a {
    display: block !important;
    color: #59A9A5 !important;
    padding: 8px 16px !important;
}

/**/

.woocommerce button.button {
    border-radius: 0 !important;
    padding: 16px 32px !important;
    background-color: $c-quaternary !important;
}

.cloud-zoom img {
    height: auto !important;
}

.woocommerce-product-gallery__image img {
    -o-object-fit: cover !important;
    object-fit: cover !important;
}

/* Square */

.woocommerce #payment div.payment_method_square_credit_card .wc-square-credit-card-hosted-field-card-number {
    padding-right: 0 !important;
    background-position: 97% 48% !important;
}

.woocommerce #payment div.payment_method_square_credit_card .wc-square-credit-card-hosted-field {
    height: 45px !important;
    padding: 8px 12px 8px 12px !important;
    box-sizing: border-box !important;
}

.woocommerce div.product form.cart .button {
    padding: 1.8rem 7.2rem 1.8rem 3.2rem !important;
    .txt {
        white-space: normal !important;
    }
}

.woocommerce .quantity .qty {
    height: 52px;
}

.woocommerce .button-wrap {
    a.button {
        width: 100% !important;
    }
}

.woocommerce .woocommerce-message {
    a.button {
        padding: 0 !important;
    }
}

.woocommerce a.button {
    display: inline-block !important;
    position: relative !important;
    padding: 1.8rem 7.2rem 1.8rem 3.2rem !important;
    letter-spacing: 0.08rem !important;
    font-weight: 700 !important;
    box-sizing: border-box !important;
    background-color: transparent !important;
    white-space: normal !important;
    .txt {
        white-space: normal !important;
    }
}

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images {
    float: unset !important;
    width: unset !important;
}

.woocommerce div.product form.cart .variations {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 25px;
        right: 10px;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #000000;
        border-right: 2px solid #000000;
        transform: rotate(45deg);
    }
}

.woocommerce div.product form.cart .variations select {
    position: relative;
    border: 1px solid $c-secondary;
    padding: 0 10px;
}

#add_payment_method table.cart img, .woocommerce-cart table.cart img, .woocommerce-checkout table.cart img {
   width: unset !important;
}

.woocommerce div.product form.cart .button {
    background-color: transparent !important;
}

.stock {
    display: none;
}

.woocommerce div.product form.cart {
    margin-top: 40px;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
    font-size: 16px !important;
    text-align: left !important;
    // width: auto !important;
}

// Padding fixes Blogue

.o-section-text+.o-section-listing{
    padding-top: 0 !important;
}

.button .wc-backward {
    color: #000000 !important;
    padding: 20px !important;
}