// ==========================================================================
// Settings / Config
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #000000;
$primary: #1A0DAB;

// Specific
// =============================================================================
// Link
$link-color: #1A0DAB;
$link-focus-color: #1A0DAB;
$link-hover-color: darken(#1A0DAB, 10%);
// Selection
$selection-text-color: #FFFFFF;
$selection-background-color: #D04F42;

// Social Colors
// =============================================================================
$facebook-color: #3B5998;
$instagram-color: #E1306C;
$youtube-color: #CD201F;
$twitter-color: #1DA1F2;

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend !default;

// Path is relative to the stylesheets directory.
$assets-path: "../" !default;

// Typefaces
// =============================================================================
$font-p: 'Avenir Roman',
sans-serif;
$font-h: 'Avenir Heavy',
sans-serif;
$font-alt: 'Avenir Light',
sans-serif;

// Typography
// =============================================================================
// Base
$font-size: 16px;
$line-height: 24px / $font-size;
$font-family: $font-p;
$color: #222222;
// Headings
$line-height-h: $line-height;
// Fluid Typography
$min-font-size-p: 16px !default;
$max-font-size-p: 18px !default;
$min-font-size-h1: 27px !default;
$max-font-size-h1: 42px !default;
$min-font-size-h2: 23px !default;
$max-font-size-h2: 34px !default;
$min-font-size-h3: 21px !default;
$max-font-size-h3: 28px !default;
$min-font-size-h4: 18px !default;
$max-font-size-h4: 24px !default;
$min-font-size-h5: 17px !default;
$max-font-size-h5: 20px !default;
$min-font-size-h6: 15px !default;
$max-font-size-h6: 18px !default;
$min-button-font-size: 15px !default;
$max-button-font-size: 20px !default;
// Weights
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

// Transitions
// =============================================================================
$speed: 0.3s;
$easing: cubic-bezier(.215,.61,.355,1);

// Spacing Units
// =============================================================================
$unit: 80px;
$unit-small: 40px;

// Container
// ==========================================================================
$container-width: 2000px;
$padding: $unit;

// Breakpoints
// =============================================================================
$from-tiny: 500px !default;
$to-tiny: $from-tiny - 1 !default;
$from-small: 700px !default;
$to-small: $from-small - 1 !default;
$from-medium: 1000px !default;
$to-medium: $from-medium - 1 !default;
$from-large: 1200px !default;
$to-large: $from-large - 1 !default;
$from-big: 1400px !default;
$to-big: $from-big - 1 !default;
$from-huge: 1600px !default;
$to-huge: $from-huge - 1 !default;
$from-enormous: 1800px !default;
$to-enormous: $from-enormous - 1 !default;
$from-gigantic: 2000px !default;
$to-gigantic: $from-gigantic - 1 !default;
$from-colossal: 2400px !default;
$to-colossal: $from-colossal - 1 !default;

// Test

[data-scroll] {
	opacity: 0;
	transition: opacity $speed linear;
}

[data-scroll].is-inview {
	opacity: 1;
}