.swiper-container {
	.swiper-wrapper {
		.swiper-slide {
			.o-layout {
				.c-bg-img {
					&::after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						background-color: rgba($black, 0.6);
					}
				}

				.o-layout_item {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;

					.o-text {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						height: 100%;

						h1,
						h2,
						h3,
						h4,
						h5,
						h6,
						p {
							color: $white;
						}
					}
				}
			}

			.o-container {
				height: 100%;
			}
		}
	}
}
