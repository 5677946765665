.c-bg-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &[data-scroll-speed] {
        top: -4.8vw;
        bottom: -4.8vw;
    }
}
