// main: ../main.scss

.contact-section {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-tertiary;
    z-index: 0;
  }

  .display {
    color: $c-secondary;
    font-size: 9.6rem;
  }


}

.contact-infos-section {
  iframe {
    margin-top: -6.4rem;
    margin-bottom: -0.4rem;
  }
  .bg-color {
    position: absolute;
    top: -6.4rem;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-primary;
    z-index: 0;
    mix-blend-mode: hue;
    pointer-events: none;
  }
}

.contact-form-section {
  color: $c-tertiary;
  h1, h2, h3, h4, h5, h6 {
    color: $c-tertiary;
  }
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-secondary;
    z-index: 0;
  }
  .button {
    .overlay1 {
      background-color: #A9D3CB;
    }
    .overlay2 {
      background-color: #59A9A5;
    }
    .stroke {
      background-color: #A9D3CB;
        border: solid 0.2rem #A9D3CB;
    }
  }
}

/* Form */

.quform .quform-field-captcha, .quform .quform-field-date, .quform .quform-field-email, .quform .quform-field-file, .quform .quform-field-multiselect, .quform .quform-field-password, .quform .quform-field-select, .quform .quform-field-text, .quform .quform-field-textarea, .quform .quform-field-time, .quform .select2-container--quform .select2-selection {
  border: none;
    border-radius: 0;
}

.quform {
  textarea {
    background-color: $white;
  }
}