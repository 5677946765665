footer {
	// @include fluid-padding($from-small, $to-enormous, $unit-small/2, $unit/2);

	.c-footer_copyrights {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin-bottom: 0;
		}
		a {
			margin-left: 10px;
			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
}