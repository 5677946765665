// Custom styles

.o-accordion-container {
	position: relative;

	.o-accordion {
		position: relative;
		margin-top: -1px;
		border: 1px solid $black;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		&.is-active {

			.o-accordion-close {
				div {
					&:nth-child(1) {
						transform: translate(-50%, -50%) rotate(-45deg);
					}

					&:nth-child(2) {
						transform: translate(-50%, -50%) rotate(-45deg);
					}
				}
			}
		}
	}

	.o-accordion-question__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		padding: $unit-small;
		padding-right: $unit-small + $unit-small/1.5;
		cursor: pointer;
		height: 80px;
	}

	.o-accordion-question {
		margin: 0;
		text-decoration: none;
		display: block;
		cursor: pointer;
		position: relative;
		user-select: none;
	}

	.o-accordion-close {
		position: absolute;
		top: 40px;
		right: $unit-small;
		transform: translateY(-50%);
		width: $unit-small/1.5;
		height: $unit-small/1.5;
		pointer-events: none;

		div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: $black;
			transition: transform $speed/2 $easing;

			&:nth-child(1) {
				width: 100%;
				height: 2px;
			}

			&:nth-child(2) {
				width: 2px;
				height: 100%;
			}
		}
	}

	.o-accordion-answer {
		overflow: hidden;

		.o-accordion-answer-content {
			margin: 0;
			padding: $unit-small;
			padding-top: $unit-small/2;
		}
	}
}