// main: ../main.scss

.le-district-section {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-tertiary;
    z-index: 0;
  }

  .display {
    color: $c-secondary;
    font-size: 9.6rem;
  }
}

.map-title {
  h2 {
    margin-bottom: 0;
  }
}

.map {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-white;
    z-index: 0;
    &.is-inview {
      left: 100%;
      transition: left $speed*2 $easing $speed;
    }
  }
}

@media (max-width: $q-desktop - 1) {
  .le-district-section {
    margin-top: 6.4rem;
  }
}

/* Carte du district */

.wpgmza_marker_address {
  display: none !important
}

.wpgmza-modern-marker-open-button {
  display: none !important
}

.wpgmza-modern-store-locator {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  z-index: 9999999 !important
}

.wpgmza-modern-store-locator>.wpgmza-inner {
  display: none !important
}

.wpgmza-modern-store-locator .wpgmza_cat_checkbox_holder {
  max-height: 100vh !important
}

.wpgmza-modern-store-locator .wpgmza_cat_checkbox_holder {
  width: 16vw !important;
  height: 100% !important;
  min-height: 800px !important
}

// Fix 1
.wpgmza-modern-store-locator .wpgmza_cat_ul {
  height: 100% !important;
  @include media("<1360px") {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.wpgmza-popout-panel:not(.wpgmza-modern-marker-listing-marker-view) {
  left: 16vw !important;
  width: 16vw !important
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li {
  display: block !important;
  font-size: 16px !important
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li label {
  display: inline-block !important;
  padding: 8px 16px !important;
  margin: 8px 8px 8px 16px !important;
  border-radius: 0 !important;
  position: relative !important;
  cursor: pointer !important;
  -webkit-transition: all ease 300ms !important;
  transition: all ease 300ms !important;
  font-weight: 400 !important;
  font-size: 20px !important
}

.wpgmza_cat_count {
  font-size: 14px !important;
  -webkit-transform: translateY(-1px) !important;
  transform: translateY(-1px) !important;
  display: inline-block !important;
  color: #59a9a5 !important
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li label, .wpgmza-chip-icon {
  border: none !important;
  background: none !important
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>.wpgmza_cat_checkbox_item_holder_1 label::before {
  background-image: url(/wp-content/uploads/2019/07/map-marker-entreprises.png)
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>.wpgmza_cat_checkbox_item_holder_3 label::before {
  background-image: url(/wp-content/uploads/2019/07/map-marker-restaurants-lieux-open.png)
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>.wpgmza_cat_checkbox_item_holder_4 label::before {
  background-image: url(/wp-content/uploads/2019/07/map-marker-restaurants-organismes-de-soutien.png)
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>.wpgmza_cat_checkbox_item_holder_2 label::before {
  background-image: url(/wp-content/uploads/2019/07/map-marker-restaurants-commerces.png)
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li label::before {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 28px;
  position: absolute;
  -webkit-transform: translate(-32px, -4px);
  transform: translate(-32px, -4px);
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li>input:checked+label::after {
  width: 48px;
  -webkit-transform: translate(-76px, -50%);
  transform: translate(-76px, -50%);
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li label::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 2px;
  position: absolute;
  -webkit-transform: translate(-76px, -50%);
  transform: translate(-76px, -50%);
  background-color: #59a9a5;
  left: 0;
  top: 18px;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li>input:checked+label {
  -webkit-transform: translateX(32px);
  transform: translateX(32px);
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms
}

.wpgmza-modern-store-locator .wpgmza_cat_ul {
  padding: 16px !important
}

.wpgmza-modern-store-locator.wpgmza_sl_offset, .wpgmza-modern-store-locator.wpgmza_sl_mv_offset {
  left: 0 !important
}

.wpgmza-modern-marker-listing .wpgmza-close-container {
  display: none !important
}

.wpgmza-popout-panel .wpgmaps_mlist_row {
  border-bottom: none !important
}

.wpgmza-modern-marker-listing-list-item-container {
  padding: 16px !important
}

.wpgmza-modern-marker-listing-buttons {
  display: none !important
}

.wpgmza-modern-marker-listing [data-name="title"] {
  font-size: 16px !important
}

.wpgmza-popout-panel {
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  z-index: 950 !important
}

.wpgmza-close:first-child {
  display: none !important
}

.wpgmza-modern-marker-listing-marker-view {
  width: 32vw !important;
  -webkit-transition: all ease 900ms !important;
  transition: all ease 900ms !important
}

.wpgmza-modern-marker-listing-marker-view .wpgmza-close-container {
  padding: 16px !important;
  background-color: #a9d3cb !important;
  box-shadow: none !important
}

.wpgmza-modern-marker-listing-marker-view .wpgmza-close-container i {
  visibility: hidden !important;
  opacity: 0 !important
}

.wpgmza-close:after {
  content: url(/wp-content/uploads/2019/07/x.svg);
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  right: 16px;
  top: 11px
}

.wpgmza-modern-marker-listing-marker-view [data-name='title'] {
  margin-top: 68px !important;
  background: none !important;
  box-shadow: none !important;
  color: #59a9a5 !important;
  font-weight: 600 !important;
  font-size: 28px !important;
  font-family: "Avenir Heavy" !important;
  padding: 32px !important
}

.wpgmza-modern-marker-listing-marker-view [data-name='title']:after {
  content: "";
  width: 32px;
  height: 2px;
  background-color: #59a9a5;
  display: block;
  margin-top: 16px;
  margin-bottom: -16px
}

.wpgmza-modern-marker-listing-marker-view [data-name='address'] {
  display: none !important
}

.wpgmza-modern-marker-listing-marker-view [data-name='desc'] {
  padding: 16px 32px !important
}

.wpgmza-modern-marker-listing-marker-view [data-name='category'] {
  padding: 0 32px !important
}

.wpgmza-modern-marker-listing-marker-view {
  color: #59a9a5 !important;
  font-family: "Avenir Roman", sans-serif !important
}

.wpgmza-modern-marker-listing-marker-view [data-name='category']:before {
  content: "Catégorie";
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px
}

@media(max-width:1360px) {
  .wpgmza-modern-store-locator {
    max-width: 100% !important;
    margin-left: 0 !important
  }

  // Fix 2
  .wpgmza-modern-store-locator .wpgmza_cat_checkbox_holder {
    width: 100% !important;
    max-width: 75vw !important;
    overflow: auto !important;
    overflow-y: hidden !important;
    margin-left: 0 !important;
    padding: 0 !important;
    background-color: #ffffff;

    @include media("<md") {
      max-width: unset !important;
    }
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul {
    height: auto !important;
    white-space: nowrap !important;
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li {
    display: inline-block !important;
    white-space: nowrap !important;
    margin-bottom: 0 !important
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li>input:checked+label {
    -webkit-transform: translateX(16px);
    transform: translateX(16px)
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li>input:checked+label::after {
    width: 16px;
    -webkit-transform: translate(-38px, -50%);
    transform: translate(-38px, -50%)
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li label::after {
    -webkit-transform: translate(-38px, -50%);
    transform: translate(-38px, -50%)
  }

  .wpgmza-popout-panel:not(.wpgmza-modern-marker-listing-marker-view) {
    left: 0 !important;
    width: 100vw !important;
    height: auto !important;
    margin-top: 78px !important
  }

  .wpgmza-modern-marker-listing-marker-view {
    width: 100% !important
  }

  .wpgmza-popout-panel {
    left: -100vw !important
  }

  .wpgmza-popout-panel.wpgmza-open {
    left: 0 !important
  }

  .wpgmza-modern-store-locator {
    height: 78px !important
  }

  .wpgmza-modern-marker-listing [data-name="title"] {
    font-size: 12px !important
  }

  .wpgmza-popout-panel .wpgmaps_mlist_row {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    margin-bottom: 4px !important
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li label {
    font-size: 12px !important
  }

  .wpgmza-modern-store-locator .wpgmza_cat_ul>li label::before {
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 22px;
    position: absolute;
    -webkit-transform: translate(-32px, -4px);
    transform: translate(-23px, -4px);
    -webkit-transition: all ease 300ms;
    transition: all ease 300ms
  }

  .wpgmza_cat_count {
    font-size: 11px !important
  }

  .wpgmza-modern-store-locator .wpgmza_cat_checkbox_holder {
    height: 78px !important;
    min-height: 78px !important
  }

  .wpgmza_map>div>div>div:first-child {
    margin-top: 240px !important
  }

  .wpgmza-modern-store-locator {
    min-width: 100vw
  }
}

.paginationjs .paginationjs-pages li {
  border: none !important;
  padding: 1px !important
}

.disabled, .active {
  pointer-events: none
}

.paginationjs .paginationjs-pages li>a {
  font-size: 12px !important;
  min-width: 28px !important
}

.paginationjs .paginationjs-pages li.active>a {
  height: 28px !important;
  line-height: 28px !important;
  font-family: "Avenir Roman", sans-serif !important;
  background: #59a9a5 !important
}

.wpgmza-pagination {
  position: inherit;
  bottom: 0
}

.wpgmaps_mlist_row:last-child {
  margin-bottom: 64px !important
}

.wpgmza-preloader {
  background-image: url(/wp-content/uploads/2019/07/loading.gif) !important;
  width: 36px !important;
  height: 36px !important
}

.map .bg-color {
  z-index: 951 !important;
  background-color: #eee !important
}

.wpgmza-modern-store-locator .wpgmza_cat_checkbox_holder {
  box-shadow: none !important
}

.wpgmza-modern-store-locator .wpgmza_cat_ul>li>input:checked+label {
  color: #231f20 !important
}

.wpgmza-modern-marker-listing-marker-view [data-name='pic'][src] {
  display: none !important
}

@supports(-ms-ime-align:auto) {

  .wpgmza-modern-marker-listing-marker-view .wpgmza-close-container i {
    opacity: 1 !important;
    visibility: visible !important
  }
}

@media all and (-ms-high-contrast:none), (-ms-high-contrast:active) {

  .wpgmza-modern-marker-listing-marker-view .wpgmza-close-container i {
    opacity: 1 !important;
    visibility: visible !important
  }
}

img {
  height: auto
}

.map .wpgmza-modern-store-locator.wpgmza_sl_offset, .map .wpgmza-modern-store-locator.wpgmza_sl_mv_offset {
    left: 0!important;
}
