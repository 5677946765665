.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: calc(100vh - 4px);
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;

    &:hover {
        transform: scaleX(1.45);
    }

    &:hover, .has-scroll-scrolling &, .has-scroll-dragging & {
        opacity: 1;
    }
}

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: grab;

    // Custom styles
    box-shadow: 0px 0px 0px 1px rgba($white, 0.45);

    .has-scroll-dragging & {
        cursor: grabbing;
    }
}

.swiper-scrollbar {
    height: 6px !important;
    .swiper-scrollbar-drag {
        background-color: $black;
        background: $black;
        box-shadow: 0px 0px 0px 1px rgba($white, 0.45);
        opacity: 0.5;
    }
}