// main: ../main.scss

nav {
  position: fixed;
  width: 100vw;
  z-index: 2002;
  pointer-events: none;
  font-family: "Avenir Medium", sans-serif;

  .navigation {
    .left-nav {
      position: relative;

      .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 23.2rem;
        pointer-events: auto;

        a {
          display: block;
          padding: 3.2rem;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .logo-mobile {
        display: none;
      }

      .toggler {
        position: relative;
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 6.4rem;
        height: 6.4rem;
        cursor: pointer;
        pointer-events: auto;

        .toggler-bg1 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $c-white;
          background-color: $c-white;
          pointer-events: none;
        }

        .toggler-bg2 {
          position: absolute;
          top: 50%;
          bottom: 50%;
          left: 50%;
          right: 50%;
          background: $c-primary;
          background-color: $c-primary;
          pointer-events: none;
        }

        span {
          position: absolute;
          width: 3.2rem;
          height: 0.2rem;
          background: $c-black;
          background-color: $c-black;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          pointer-events: none;
        }

        span:first-child {
          -webkit-transform: translate(-50%, -500%);
          transform: translate(-50%, -500%);
        }

        span:last-child {
          -webkit-transform: translate(-50%, 400%);
          transform: translate(-50%, 400%);
        }

        .toggler-txt {
          position: relative;
          display: block;
          color: $c-secondary;
          top: 8.8rem;
          left: -1.8rem;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          font-size: 1.2rem;
          letter-spacing: 0.4rem;
          pointer-events: none;
        }
      }
    }

    .right-nav {
      position: relative;

      .events {
        position: absolute;
        top: 3.2rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin-right: auto;
        margin-left: auto;
        width: 6.4rem;
        height: 6.4rem;
        cursor: pointer;
        pointer-events: auto;

        .events-bg1 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $c-white;
          background-color: $c-white;
          pointer-events: none;
        }

        .events-bg2 {
          position: absolute;
          top: 50%;
          bottom: 50%;
          left: 50%;
          right: 50%;
          background: $c-primary;
          background-color: $c-primary;
          pointer-events: none;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .svg {
          position: absolute;
          color: $c-black;
          height: 2.4rem;
          width: 2.4rem;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: color ease-in 0.48s;
          transition: color ease-in 0.48s;
        }

        .events-txt {
          position: relative;
          display: block;
          color: $c-secondary;
          top: 10.4rem;
          left: 1.4rem;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          font-size: 1.2rem;
          letter-spacing: 0.4rem;
          pointer-events: none;
        }
      }

      .events:hover .svg {
        color: $c-white;
        -webkit-transition: color ease-out 0.32s;
        transition: color ease-out 0.32s;
      }

      .language {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 43.95vh;
        left: calc(50% + 2rem);
        pointer-events: auto;

        .language-txt {
          position: relative;
          -webkit-transform: translate(-40%, -50%);
          transform: translate(-40%, -50%);
          font-size: 1.6rem;
          letter-spacing: 0.4rem;
          text-align: center;

          .language-txt-hover {
            position: absolute;
            top: -0.7rem;
            bottom: -0.7rem;
            left: -1.6rem;
            right: calc(100% + 1.9rem);
            background: $c-black;
            background-color: $c-black;
            pointer-events: none;
            z-index: -1;
          }

          .language-txt-hover2 {
            position: absolute;
            top: -0.7rem;
            bottom: -0.7rem;
            left: -1.6rem;
            right: calc(100% + 1.6rem);
            background: $c-primary;
            background-color: $c-primary;
            pointer-events: none;
            z-index: -1;
          }
        }

        a {
          color: $c-black;
        }
      }

      .social {
        position: relative;
        top: calc(100vh - 17.6rem);
        margin-right: auto;
        margin-left: auto;
        width: 4.8rem;
        height: 4.8rem;
        cursor: pointer;
        pointer-events: auto;

        .social-bg1 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $c-white;
          background-color: $c-white;
          pointer-events: none;
        }

        .social-bg2 {
          position: absolute;
          top: 50%;
          bottom: 50%;
          left: 50%;
          right: 50%;
          background: $c-primary;
          background-color: $c-primary;
          pointer-events: none;
        }

        .svg {
          position: absolute;
          color: $c-black;
          height: 2.4rem;
          width: 2.4rem;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: color ease-in 0.48s;
          transition: color ease-in 0.48s;
          z-index: 2;
        }

        .social-txt {
          position: absolute;
          display: block;
          color: $c-secondary;
          top: -7.6rem;
          left: -2.7rem;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          font-size: 1.2rem;
          letter-spacing: 0.4rem;
          pointer-events: none;
          width: 13rem;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .social:hover .svg {
        color: $c-white;
        -webkit-transition: color ease-out 0.32s;
        transition: color ease-out 0.32s;
      }
    }
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
@media (max-height: 680px) {
  nav .navigation .right-nav .events .events-txt {
    display: none !important;
  }

  nav .navigation .right-nav .social .social-txt {
    display: none !important;
  }
}
/* Responsive
————————————————————————————————————————————————————————————————————————————— */
@media (max-width: $q-desktop - 1) {
  nav {
    .navigation {
      .right-nav {
        display: none;
      }

      .two.columns.non-responsive {
        -webkit-box-flex: 100%;
        flex: 100%;
        max-width: 100%;
      }

      .left-nav {
        background-color: $c-white;
        height: 6.4rem;

        .logo {
          display: none;
        }

        .logo-mobile {
          display: block;
          margin: 0;
          max-width: 13.8rem;
          pointer-events: auto;

          a {
            display: block;
            padding: 1.6rem;
          }

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        .toggler {
          position: absolute;
          top: 0;
          right: 0;

          .toggler-txt {
            display: none;
          }
        }

        .div-32 {
          display: none;
        }
      }
    }

    .menu {
      .menu-list {
        font-size: 2.8rem !important;
        padding: 3.2rem !important;
      }

      .columns {
        margin-top: 6.4rem;
        min-height: unset !important;
      }

      .menu-contact {
        padding: 3.2rem 3.2rem 0;
        margin-top: -6.4rem;
        margin-bottom: 20px;
      }
    }
  }
}
@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}
@media (max-width: 1001px) {
  .hide-mobile {
    display: none;
  }
}

.reveal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999999;
}
@media (max-width: 999px) {
  .social-mobile {
    display: inline-block;
    margin-right: 8px;
  }
}
/* Infolettre */
.infolettre {
  position: fixed;
  top: calc(100vh - 9.6rem);
  margin-right: auto;
  margin-left: auto;
  width: 6.4rem;
  height: 6.4rem;
  cursor: pointer;
  pointer-events: auto;
  left: calc(8.333333% - 3.2rem);

  .infolettre-txt {
    position: absolute;
    display: block;
    color: #59A9A5;
    top: -8.6rem;
    left: -4.8rem;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    pointer-events: none;
    width: 13rem;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .infolettre-bg1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    background-color: #fff;
    pointer-events: none;
  }

  .infolettre-bg2 {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    background: #D04F42;
    background-color: #D04F42;
    pointer-events: none;
  }

  .infolettre-bg3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;
    background: #231f20;
    background-color: #231f20;
    pointer-events: none;
    z-index: 3;
  }

  .svg {
    position: absolute;
    color: #231f20;
    height: 2.4rem;
    width: 2.4rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: color ease-in 0.48s;
    transition: color ease-in 0.48s;
    z-index: 2;
  }
  .svg-wrapper-2 svg {
    opacity: 0;
    -webkit-transition: all ease-out 300ms;
    transition: all ease-out 300ms;
    -webkit-transition-delay: 600ms;
            transition-delay: 600ms;
  }
  .svg-2 {
    position: absolute;
    color: #ffffff;
    height: 2.4rem;
    width: 2.4rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: color ease-in 0.48s;
    transition: color ease-in 0.48s;
    z-index: 4;
  }
}

.infolettre:hover {
  .svg {
    color: #fff;
    -webkit-transition: all ease-out 0.32s;
    transition: all ease-out 0.32s;
  }
}

.infolettre.open {
  .svg-wrapper-2 svg {
    opacity: 1;
    -webkit-transition: all ease-out 300ms;
    transition: all ease-out 300ms;
    -webkit-transition-delay: 160ms;
            transition-delay: 160ms;
  }
}

@media (max-height: 680px) {
.infolettre .infolettre-txt {
    display: none !important;
}
}

.infolettre-popup {
  position: fixed;
  z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    height: 250px;
    overflow: hidden;
    .infolettre-popup-wrapper {
      position: relative;
      height: 250px;
      pointer-events: all;
      .bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        background-color: #D04F42;
      }
    }

    #mc_embed_signup {
      position: absolute;
background-color: transparent;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
width: 50%;
opacity: 0;
visibility: hidden;
input.email {
  width: 100%;
  border-radius: 0;
  margin-bottom: 24px;
}
.button {
  line-height: 1;
  background-color: #59A9A5;
}
.button:hover {
  background-color: #A9D3CB;
}
input {
  border: none;
}
input.button {
  margin-bottom: 0;
  border-radius: 0;
  width: auto;
padding: 16px 28px;
height: auto;
}
label {
  font-size: 32px;
  color: #ffffff;
  padding: 0;
  margin-bottom: 32px;
}
form {
  margin-bottom: 0;
}
    }
}

@media (max-width: 999px) {
  .infolettre {
    display: none;
  }
}

@media (min-width: 1000px) {
  .lang-switcher {
    display: none;
  }
}
