// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%;
    /* [2] */
    color: $color;
    font-family: $font-family;
    line-height: $line-height;
    /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }

    &.reload {
        background-color: $c-secondary;
    }
}

body {
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;

    @include u-hocus {
        color: $link-hover-color;
    }
}

.o-preloader {
    z-index: 1003;
}

header {
    z-index: 1001;
}

main {
    // padding-top: 80px;
    z-index: 1000;
}

footer {
    z-index: 1000;
}

.c-scrollbar {
    z-index: 1002;
}

.o-pagination-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.o-pagination-blog, .o-pagination-list {
    text-align: center;
    font-size: 0;

    .page-numbers {
        margin-left: $unit-small/5/2;
        margin-right: $unit-small/5/2;
    }

    a,
    span:not(.link) {
        display: inline-block;
        width: $unit-small/1.5;
        height: $unit-small/1.5;
        background-color: $white;
        padding: 5px;
        font-size: 15px;

        &.current {
            background-color: $c-secondary;
            color: $white !important;
            cursor: default;
        }
    }

    a {
        color: $black;
        transition: color $speed $easing, background-color $speed $easing;

        &:hover {
            background-color: $c-secondary;
            color: $white;
        }
    }

    span {
        display: inline-block;
        color: $c-secondary;

        &.link {
            &.active {
                .page {
                    background-color: $c-secondary;
                    color: $white !important;
                    cursor: default;
                }
            }

            &.disabled {
                .page {
                    color: $c-secondary !important;
                }
            }

            .page {
                margin-left: $unit-small/5/2;
                margin-right: $unit-small/5/2;
                display: inline-block;
                width: $unit-small/1.5;
                height: $unit-small/1.5;
                background-color: $white;
                padding: 5px;
                font-size: 15px;
                color: $black;
                cursor: pointer;
                transition: color $speed $easing, background-color $speed $easing;

                &:hover {
                    background-color: $c-secondary;
                    color: $white;
                }
            }
        }
    }

    .prev {
        margin-right: $unit-small/3;
        .page {
            margin-right: 0 !important;
        }
    }

    .next {
        margin-left: $unit-small/3;
        .page {
            margin-left: 0 !important;
        }
    }

    .dots {
        cursor: default;
    }

    .disable-nav {
        display: none;
    }
}