// main: ../main.scss

.news-section {
  position: relative;

  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-tertiary;
    z-index: 0;
  }

  .display {
    color: $c-secondary;
    font-size: 9.6rem;
  }

  p {
    color: $c-white;
  }
}

.press-section {
  margin: auto;
  width: 70%;

  .press {
    position: relative;
    padding: 3.2rem;

    .date {
      position: relative;
      display: inline-block;
      color: $c-white;
      background-color: $c-primary;
      padding: 0.8rem 1.2rem;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
      z-index: 2;
    }

    .press-img-wrap {
      overflow: hidden;
      background-color: $c-secondary;
      position: relative;

      img {
        display: block;
      }

      .press-img {
        -webkit-transition: all ease-out 400ms;
        transition: all ease-out 400ms;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }

      .press-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .press-img-wrap:hover .press-img {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    .content-wrap {
      position: relative;
      padding: 3.2rem;

      .bg-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $c-white;
        z-index: 0;
      }

      .content {
        position: relative;
        z-index: 2;

        .button {
          padding: 1.0rem 6.0rem 1.0rem 2.0rem;

          .stroke {
            border: solid 0.2rem $c-secondary;
          }

          .icon,
          .txt {
            color: $c-primary;
          }

          .icon {
            right: 2.0rem;
          }

          .overlay1 {
            background-color: $c-secondary;
          }

          .overlay2 {
            background-color: $c-tertiary;
          }
        }

        h4 {
          a {
            color: $c-primary;
            -webkit-transition: all ease 300ms;
            transition: all ease 300ms;
          }

          a:hover {
            color: $c-secondary;
          }
        }
      }

      .line {
        display: inline-block;
        width: 5.6rem;
        height: 0.2rem;
        background: $c-secondary;
        background-color: $c-secondary;
        margin-bottom: 0.5rem;
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: 1400px - 1) {
  .press-section {
    width: 80%;

    .press {
      padding: 1.6rem;
    }
  }
}

@media (max-width: $q-desktop-hd - 1) {
  .press-section {
    .press {
      padding: 0.8rem;
    }
  }

  .news-section {

    .display {
      color: $c-secondary;
      font-size: 7.2rem;
    }

  }
}

@media (max-width: $q-desktop - 1) {
  .news-section {
    margin-top: 6.4rem;
  }

  .press-section {
    width: 100%;

    .press {
      padding: 3.2rem;
    }
  }

  .div-160 {
    height: 9.6rem !important;
  }
}

@media (max-width: $q-phablet - 1) {
  .div-160 {
    height: 6.4rem !important;
  }
}

.iframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}