.pastille-header-video {

    .bg-color {
        color: $c-quaternary;
        background-color: $c-secondary;
    }
    .padding {
        padding: 5vw;
        p {
			color: $c-white;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    video {
        width: 100%;
        height: auto;
        display: block;
    }

    .video_wrap {
        pointer-events: all;

        .video {
            position: relative;
            .play-btn-alt {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              }
        }
        img {
            display: block;
        }
        p {
            background-color: $c-white;
            padding: 32px;
            font-size: 14px;
            line-height: 1.8;
            margin-bottom: 0;
        }
    }

    @media (max-width: $q-desktop) {
        margin-top: 62px;
    }
}

.inset-top {
    position: absolute;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    @media (max-width: $q-desktop) {
        position: relative;
        transform: none;
    }
}

.pastille-texte {
    .padding {
        padding: 5vw;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .paddingX {
        padding: 0 5vw 5vw 5vw;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            margin-bottom: 32px;
        }
    }
}

.pastille-image {
    padding: 0 5vw 5vw 5vw;
    img {
        display: block;
    }
    p {
        background-color: $c-white;
        padding: 32px;
        font-size: 14px;
        line-height: 1.8;
    }
}

//

img {
	height: auto
  }
  
  a {
	color: #a9d3cb
  }
  
  .bg-img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-size: cover;
	background-position: center;
  }
  
  .projets .button .txt,
  .projets .button .icon {
	color: $c-primary;
  }

  .pastille_wrap {
	  position: relative;
	  .bg-color {
		background-color: $c-white;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	  }
  }

  .pastille {
	  position: relative;
	  z-index: 1;
  }

  .pastille-titre {
	  position: relative;
	  padding: 32px;

	  h2 {
		  position: relative;
	  }
  }

  .pastille-button {
      .button {
        .txt, .icon {
            color: $c-primary;
        }
      }
  }

  .pastille-texte {
    ul {
        li {
            a {
                color: $c-primary;
            }
        }
    }
}

.pastille-citation {
    background-color: #fff;
    border-top: solid 1px $c-quaternary;
    p {
        background-color: unset !important;
        font-weight: bolder;

        &:last-child {
            margin-top: -30px;
        }
    }
}