// main: ../main.scss

.le-district-section {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-tertiary;
    z-index: 0;
  }

  .display {
    color: $c-secondary;
    font-size: 9.6rem;
  }

  p {
    color: $c-white;
  }
}

.map-title {
  h2 {
    margin-bottom: 0;
  }
}

.map {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-white;
    z-index: 0;
  }
}
