// main: ../main.scss

.button {
  display: inline-block;
  position: relative;
  padding: 1.8rem 7.2rem 1.8rem 3.2rem;
  letter-spacing: 0.08rem;
  font-weight: 700;

  .stroke {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    border: solid 0.2rem $c-secondary;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .txt {
    position: relative;
    display: inline-block;
    color: $c-white;
    // white-space: nowrap;
  }

  @media (max-width: 480px) {
    .txt {
      font-size: 13px;
    }
  }

  .icon {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    color: $c-white;
    top: 50%;
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%);
    right: 3.2rem;
  }

  .overlay1 {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 100%;
    background-color: $c-secondary;
  }

  .overlay2 {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 100%;
    background-color: $c-tertiary;
  }
}

.button-wrap {
  overflow: hidden;
}

.hidden-left {
  margin-left: -600px;
}

.button-center {
display: table;
  margin: auto;
}

/* Edge fix */

_:-ms-lang(x), .button .overlay1 {
  display: none;
}

_:-ms-lang(x), .button .overlay2 {
  display: none;
}

_:-ms-lang(x), .button .txt {
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
  transition-delay: 0.48s;
}

_:-ms-lang(x), .button:hover .txt {
  opacity: 0;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}
