.c-divider-small {
    display: block;
    width: 100%;
    height: $unit-small;
    &\@from-tiny {
        height: $unit-small;
        @media (max-width: $from-tiny) {
            height: 0;
        }
    }
    &\@from-small {
        height: $unit-small;
        @media (max-width: $from-small) {
            height: 0;
        }
    }
    &\@from-medium {
        height: $unit-small;
        @media (max-width: $from-medium) {
            height: 0;
        }
    }
    &\@from-large {
        height: $unit-small;
        @media (max-width: $from-large) {
            height: 0;
        }
    }
    &\@from-big {
        height: $unit-small;
        @media (max-width: $from-big) {
            height: 0;
        }
    }
    &\@from-huge {
        height: $unit-small;
        @media (max-width: $from-huge) {
            height: 0;
        }
    }
    &\@from-enormous {
        height: $unit-small;
        @media (max-width: $from-enormous) {
            height: 0;
        }
    }
    &\@from-gigantic {
        height: $unit-small;
        @media (max-width: $from-gigantic) {
            height: 0;
        }
    }
    &\@from-colossal {
        height: $unit-small;
        @media (max-width: $from-colossal) {
            height: 0;
        }
    }
}
.c-divider {
    display: block;
    width: 100%;
    height: $unit;
    &\@from-tiny {
        height: $unit;
        @media (max-width: $from-tiny) {
            height: 0;
        }
    }
    &\@from-small {
        height: $unit;
        @media (max-width: $from-small) {
            height: 0;
        }
    }
    &\@from-medium {
        height: $unit;
        @media (max-width: $from-medium) {
            height: 0;
        }
    }
    &\@from-large {
        height: $unit;
        @media (max-width: $from-large) {
            height: 0;
        }
    }
    &\@from-big {
        height: $unit;
        @media (max-width: $from-big) {
            height: 0;
        }
    }
    &\@from-huge {
        height: $unit;
        @media (max-width: $from-huge) {
            height: 0;
        }
    }
    &\@from-enormous {
        height: $unit;
        @media (max-width: $from-enormous) {
            height: 0;
        }
    }
    &\@from-gigantic {
        height: $unit;
        @media (max-width: $from-gigantic) {
            height: 0;
        }
    }
    &\@from-colossal {
        height: $unit;
        @media (max-width: $from-colossal) {
            height: 0;
        }
    }
}
