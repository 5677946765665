// main: ../main.scss
/* Header
————————————————————————————————————————————————————————————————————————————— */
.header-section {
  position: relative;
  margin-bottom: 9.6rem;

  .header-content {
    position: relative;
    height: calc(100vh - 12.8rem);
    min-height: 750px;
    top: 6.4rem;

    .bg-color {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      background-color: $c-primary;
    }

    .txt-wrap {
      overflow: hidden;

      h1 {
        position: relative;
        color: $c-white;
        font-size: 7.6rem;
        margin-left: -600px;
      }
    }

    .open {
      position: relative;
      -webkit-transform: rotate(-90deg) translateY(85%);
      transform: rotate(-90deg) translateY(85%);
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
      overflow: hidden;

      h2 {
        display: inline-block;
        color: $c-secondary;
        font-size: 3.2rem;
        margin-left: -200px;
      }

      .line {
        display: inline-block;
        // width: 5.6rem;
        width: 0;
        height: 0.2rem;
        background: $c-secondary;
        background-color: $c-secondary;
        margin-bottom: 0.7rem;
      }
    }

    .content-wrap {
      position: relative;
      height: calc(100vh - 12.8rem);
      min-height: 750px;

      .content {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 2;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      background-color: $c-secondary;
      z-index: 1;
    }

    .overlay3 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $c-primary;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
    }

    .bg-img {
      position: absolute;
      top: 48%;
      bottom: -54%;
      left: 0;
      right: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      visibility: hidden;
      opacity: 0;
    }

    .scroll {
      position: absolute;
      bottom: 0;
      background-color: $c-black;
      left: 50%;
      width: 6.4rem;
      height: 6.4rem;
      z-index: 4;
      -webkit-transform: translate(-50%, 50%) scaleX(0);
      transform: translate(-50%, 50%) scaleX(0);
      -webkit-transform-origin: left;
      transform-origin: left;
      overflow: hidden;
      cursor: pointer;

      .svg-wrap {
        position: absolute;
        color: $c-white;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-top: -96px;
      }

      .scroll-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        background-color: $c-primary;
      }
    }
  }
}
/* About
————————————————————————————————————————————————————————————————————————————— */
.about-section {
  position: relative;

  .title {
    position: relative;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-80%);
      transform: translateX(-80%);
      font-size: 15.6rem;
      opacity: 0.08;
      letter-spacing: -0.78rem;
    }
  }

  .title-alt {
    line-height: 4rem;
  }
}
/* Testimonials
————————————————————————————————————————————————————————————————————————————— */
.testimonials-section {
  position: relative;
  color: $c-white;

  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-secondary;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-quaternary;
    z-index: 1;
  }

  .bg-img {
    position: absolute;
    top: -4%;
    bottom: -4%;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .duration-line {
    position: absolute;
    right: 100%;
    left: 0;
    top: 0;
    height: 4px;
    background-color: $c-quaternary;
  }

  .content-wrap {
    overflow: hidden;
    position: relative;
    min-height: 60rem;
  }

  .quote-wrap {
    position: absolute;
    .quote {
      position: relative;
    }

    .quote-name {
      font-family: "Avenir Light", sans-serif;
      font-style: italic;
      font-size: 1.6rem;
      position: relative;
    }
  }


  /* Slider */
  .slider-controls {
    position: relative;
    height: 100%;

    .slider-controls-wrap {
      position: absolute;
      top: 50%;
      right: 0;
      height: 12.8rem;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;

      .next-button {
        position: absolute;
        right: -6.4rem;
        margin-top: 6.4rem;
        background-color: $c-black;
        width: 6.4rem;
        height: 6.4rem;
        z-index: 4;
        overflow: hidden;
        cursor: pointer;

        .svg-wrap {
          position: absolute;
          color: $c-white;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

        .next-button-bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 100%;
          background-color: $c-secondary;
        }
      }

      .back-button {
        position: absolute;
        right: -3.2rem;
        background-color: $c-white;
        width: 6.4rem;
        height: 6.4rem;
        z-index: 4;
        overflow: hidden;
        cursor: pointer;

        .svg-wrap {
          position: absolute;
          color: $c-black;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

        .back-button-bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 100%;
          background-color: $c-secondary;
        }
      }
    }
    @media (max-width: $q-desktop - 1) {
      .slider-controls-wrap {
        right: 6.4rem;
        bottom: 0;
      }
    }
  }
}
/* Counters
————————————————————————————————————————————————————————————————————————————— */
.counters-section {
  position: relative;
  margin-top: -12.8rem;
  margin-left: -6.4rem;

  .bg-color {
    position: absolute;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-light-grey;
  }

  h1 {
    position: relative;
    color: $c-quaternary;
    visibility: hidden;
    opacity: 0;
  }

  p {
    position: relative;
    color: $c-secondary;
    visibility: hidden;
    opacity: 0;
  }

  .line-48 {
    position: relative;
    margin: auto;
    visibility: hidden;
    opacity: 0;
  }

  .counter2 {
    margin-top: 3.2rem;
  }

  .counter3 {
    margin-top: 6.4rem;
  }
}
/* District
————————————————————————————————————————————————————————————————————————————— */
.district-section {
  margin-top: -12.8rem;

  .button {
    .stroke {
      border: solid 0.2rem $c-quaternary;
      background-color: $c-quaternary;
    }

    .icon,
    .txt {
      color: $c-secondary;
    }

    .overlay1 {
      background-color: $c-quaternary;
    }

    .overlay2 {
      background-color: $c-tertiary;
    }
  }
}
/* Events
————————————————————————————————————————————————————————————————————————————— */
.events-section {
  position: relative;

  .row {
  
    &.--row-evenements{
      margin-bottom: 12.8rem;
      
      &:nth-child(odd){
        justify-content: flex-end;
      }

      &:nth-child(even){
        justify-content: flex-start;
      }
    }
  }

  a {
    &:hover {
      img {
        transform: scale(1.025);
      }
    }
    img {
      transition: transform $speed $easing;
    }
  }

  .date {
    position: relative;
    display: inline-block;
    color: $c-white;
    background-color: $c-primary;
    padding: 0.8rem 1.2rem;
    z-index: 2;
  }

  .event-img-wrapper {

    .event-img {
      display: block;
    }
    .event-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      background-color: $c-quaternary;
      opacity: 0.9;
      pointer-events: none;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms;
    }
    .icon {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      color: $c-white;
      top: 60%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms;
    }
  }

    .event-img-wrapper:hover .overlay {
      right: 0%;
    }

    .event-img-wrapper:hover .icon {
      top: 50%;
      opacity: 1;
    }

  .event-info-wrapper {
    padding: 3.2rem 6.4rem;
    background-color: $c-white;
    height: 100%;
    margin-left: -32px;

    .line {
      display: inline-block;
      width: 4.8rem;
      height: 0.2rem;
      background: $c-secondary;
      background-color: $c-secondary;
      margin-bottom: 0.5rem;
      margin-right: 1.2rem;
    }

    h4 {
      a {
        color: $c-black;
        -webkit-transition: all ease 300ms;
        transition: all ease 300ms;
      }
      a:hover {
        color: $c-secondary;
      }
    }

    p {
      display: inline-block;
      color: $c-secondary;
    }

    .button {
      .stroke {
        border: solid 0.2rem $c-secondary;
      }

      .icon,
      .txt {
        color: $c-primary;
      }

      .overlay1 {
        background-color: $c-secondary;
      }

      .overlay2 {
        background-color: $c-tertiary;
      }
    }

    .button-wrap {
      min-width: 20.0rem;
    }
  }

  @media (max-width: $q-desktop - 1) {
    .event-info-wrapper {
      margin-left: 0;
    }
  }

  .all-events-section {
    .button {
      .stroke {
        border: solid 0.2rem $c-primary;
        background-color: $c-primary;
      }

      .icon,
      .txt {
        color: $c-white;
        -webkit-transition: color ease-in 0.48s;
        transition: color ease-in 0.48s;
      }

      .overlay1 {
        background-color: $c-primary;
      }

      .overlay2 {
        background-color: $c-tertiary;
      }
    }

    .button:hover {
      .icon,
      .txt {
        color: $c-white;
        -webkit-transition: color ease-out 0.32s;
        transition: color ease-out 0.32s;
      }
    }
  }
}
@media (min-width: 1000px) {
  .inset-by-one {
    margin-left: -8.333333%;
  }

  .inset-by-two {
    margin-left: -16.666667%;
  }

  .inset-by-three {
    margin-left: -25%;
  }

  .inset-by-seven {
    margin-left: -58.333333%;
  }
}

.parallax {
  overflow: hidden;
}
/* Responsive
————————————————————————————————————————————————————————————————————————————— */
@media (max-width: $q-desktop - 1) {
  .header-section {
    .header-content {
      padding: 6.4rem;
      height: 100%;
      min-height: 60.0rem;

      .overlay {
        display: none;
      }

      .header-overlay {
        -webkit-transform: translateY(calc(-100% - 6.4rem));
        transform: translateY(calc(-100% - 6.4rem));
      }

      .header-img {
        display: none;
      }

      .scroll {
        right: 3.2rem;
        bottom: 3.2rem;
        left: unset;
      }

      .content-wrap {
        position: relative;
        height: 100%;

        .content {
          position: relative;
          top: 9.6rem;
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }
  }

  .about-section {
    .column,
    .columns {
      padding: 6.4rem;
    }

    .div-160 {
      height: 9.6rem;
    }
  }

  .testimonials-section {
    width: 60%;
    .content-wrap {
      padding: 0;
    }

    .div-160 {
      height: 9.6rem;
    }
    .container-fluid-inside {
      width: 80%;
    }
  }

  .counters-section {
    margin-left: 0;

    .div-96 {
      height: 6.4rem;
    }
  }

  .district-section {
    margin-top: 3.2rem;
    padding: 3.2rem;

    .button {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  .all-events-section {
    padding: 3.2rem;

    .button {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}
@media (max-width: $q-phablet - 1) {
  .header-section {
    .header-content {
      padding: 3.2rem;

      .txt-wrap {
        h1 {
          font-size: 4.4rem;
        }
      }

      .div-64 {
        height: 3.2rem;
      }

      .scroll {
        right: 0;
        bottom: 0;
        left: unset;
      }
    }
  }

  .about-section {
    .column,
    .columns {
      padding: 3.2rem;
    }

    .title {
      h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 15.6rem;
        opacity: 0.08;
      }
    }

    .div-160 {
      height: 6.4rem;
    }
  }

  .testimonials-section {
    width: 100%;
    .content-wrap {
      padding: 0;
    }

    .div-160 {
      height: 6.4rem;
    }
  }

  .counters-section {
    margin-left: 0;

    .div-96 {
      height: 4.8rem;
    }
  }

  .district-section {
    margin-top: 3.2rem;
    padding: 3.2rem;

    .button {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

/**/

.img-separator {
  img {
    display: block;
  }
}
