.o-preloader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $black;

	transition: opacity $speed $easing $speed/6, visibility $speed $easing $speed/6;

	.is-loading & {
		svg {
			top: calc(50%) !important;
			transition: top $speed $easing;
		}

		.o-preloader_spinner {
			top: calc(50% + 80px) !important;
			transition: top $speed $easing;
		}
	}

	.is-ready & {
		opacity: 0;
		visibility: hidden;

		svg {
			top: calc(50% + 40px) !important;
			transition: top $speed $easing;
		}

		.o-preloader_spinner {
			top: calc(50% + 120px) !important;
			transition: top $speed $easing;
		}
	}

	.reload & {
		opacity: 1;
		visibility: visible;

		svg {
			top: calc(50%) !important;
			transition: top $speed $easing;
		}

		.o-preloader_spinner {
			top: calc(50% + 80px) !important;
			transition: top $speed $easing;
		}
	}

	svg {
		top: calc(50%) !important;
		fill: $c-quaternary;
		transition: top $speed $easing;
	}

	.o-preloader_spinner {
		top: calc(50% + 80px) !important;
		animation: rotating 1s linear infinite;
		transition: top $speed $easing;
	}
}

@keyframes rotating {
	from {
	  transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
	  transform: translate(-50%, -50%) rotate(360deg);
	}
  }