// main: ../main.scss

.projets {
  em {
    background-color: #59a9a5;
    padding: 6px 24px;
    font-style: normal;
    display: inline-block;
    color: #ffffff;
  }
}
