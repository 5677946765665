// main: ../main.scss

.evenement-section {
  position: relative;
  z-index: 3;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-white;
    z-index: 0;
  }

.date {
  background-color: $c-primary;
  display: inline-block;
  color: $c-white;
  padding: 0.8rem 2.4rem;
}

.padding {
  opacity: 0;
  visibility: hidden;
}

}

.event-info-section {
  .event-info {
    padding: 0 3.2rem;
    ul {
      list-style-type: none;
      padding-left: 4.5rem;

      li {
        margin-bottom: 3.2rem;
        font-size: 1.8rem;
        line-height: 3.2rem;
      }

      li:before {
        content: "";
        display: block;
        position: absolute;
        width: 2.4rem;
        height: 0.2rem;
        background-color: $c-tertiary;
        margin-left: -42px;
        margin-top: 14px;
      }
    }
  }

}

.side-bar {
  position: relative;
  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-white;
    z-index: 0;
  }
  .date-wrap {
    position: relative;
    z-index: 3;
    padding: 0 3.2rem;
    .icon {
      color: $c-primary;
      display: inline-block;
    }
    .line-list {
      width: 4.8rem;
      height: 2px;
      background-color: $c-primary;
      display: inline-block;
      margin-left: 0.8rem;
      margin-bottom: 1px;
    }
  }
  .button-wrap {
    position: relative;
    z-index: 3;
    padding: 3.2rem;
    .button {
      .stroke {
        border: solid 0.2rem $c-primary;
        background-color: $c-primary;
      }

      .icon,
      .txt {
        color: $c-white;
        -webkit-transition: color ease-in 0.48s;
        transition: color ease-in 0.48s;
      }

      .overlay1 {
        background-color: $c-primary;
      }

      .overlay2 {
        background-color: $c-tertiary;
      }
    }
  }
}
