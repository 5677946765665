// main: ../main.scss

/* Colors [c-]
————————————————————————————————————————————————————————————————————————————— */;
$c-primary: #D04F42;
$c-secondary: #59A9A5;
$c-tertiary: #A9D3CB;
$c-quaternary: #F3D33C;
$c-black: #231f20;
$c-white: #ffffff;
$c-light-grey: #eeeeee;
/* Fonts [f-]
————————————————————————————————————————————————————————————————————————————— */
@font-face {
  font-family: "Avenir Light";
  src: url("../fonts/Avenir-Light.woff") format('woff');
}
@font-face {
  font-family: "Avenir Book";
  src: url("../fonts/Avenir-Book.woff") format('woff');
}
@font-face {
  font-family: "Avenir Roman";
  src: url("../fonts/Avenir-Roman.woff") format('woff');
}
@font-face {
  font-family: "Avenir Medium";
  src: url("../fonts/Avenir-Medium.woff") format('woff');
}
@font-face {
  font-family: "Avenir Heavy";
  src: url("../fonts/Avenir-Heavy.woff") format('woff');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir Heavy";
}

p {
  font-family: "Avenir Roman", sans-serif;
  font-size: 1.8rem;
  line-height: 3.2rem;
  letter-spacing: 0.08rem;
}

strong {
  font-family: "Avenir Heavy";
}
$f-primary: "Avenir", sans-serif;
/* Queries [q-]
————————————————————————————————————————————————————————————————————————————— */
$q-mobile: 400px;
$q-phablet: 550px;
$q-tablet: 750px;
$q-desktop: 1000px;
$q-desktop-hd: 1200px;
/* Base
—————————————————————————————————————————————————————————————————————————————— */
html {
  width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Avenir Roman", sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $c-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.viewport {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scroll-container {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

::-moz-selection {
  /* Code for Firefox */
  color: $c-white;
  background: $c-primary;
}

::selection {
  color: $c-white;
  background: $c-primary;
}
/* Main background
————————————————————————————————————————————————————————————————————————————  */

.o-setion-main-bg {
    position: fixed;
  .main-bg {
    height: 100vh;
    background: $c-light-grey;
    background-color: $c-light-grey;
    z-index: -1;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.white {
  background-color: $c-white !important;
}

.primary  {
  background-color: $c-primary !important;
}

.secondary  {
  background-color: $c-secondary !important;
}

.tertiary {
  background-color: $c-tertiary !important;
}

.quaternary {
  background-color: $c-quaternary !important;
}

img {
  max-width: 100%;
}

p a, span a {
  color: $c-primary;
  text-decoration: underline;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}

p a:hover, span a:hover {
  text-decoration: underline;
  color: $c-secondary;
}

@media (max-width: $q-desktop - 1) {
  .padding {
    padding: 0 3.2rem;
  }
  .display {
    font-size: 5.6rem !important;
  }
}

.text-animation {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(70px);
          transform: translateY(70px);
          transition: all $speed*3 $easing $speed/2;

          &.is-inview {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
}

.list-animation {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(70px);
          transform: translateY(70px);
          transition: all $speed*3 $easing $speed/2;

          &.is-inview {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
}

.img-animation {
  opacity: 0;
  visibility: hidden;
  transform: translateY(70px);
  transition: all $speed*3 $easing $speed/2;
  &.is-inview {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.head {
  .padding {
    opacity: 0;
    visibility: 0;
    -webkit-transform: translateY(70px);
            transform: translateY(70px);
  }
  .bg-color {
    right: 100%;
  }
}
