// main: ../main.scss


.footer {
  position: relative;
  overflow: hidden;

  .bg-img {
    position: absolute;
    top: -4%;
    bottom: -4%;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .content-wrap {
    position: relative;
    height: calc(100vh - 8.2rem);
    min-height: 750px;

    .content {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;

      .open {
        position: relative;
        -webkit-transform: rotate(-90deg) translateY(85%);
        transform: rotate(-90deg) translateY(85%);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
        overflow: hidden;

        h2 {
          display: inline-block;
          color: $c-secondary;
          font-size: 3.2rem;
          margin-left: -200px;
        }

        .line {
          display: inline-block;
          // width: 5.6rem;
          width: 0;
          height: 0.2rem;
          background: $c-secondary;
          background-color: $c-secondary;
          margin-bottom: 0.7rem;
        }
      }

      .txt-wrap {
        overflow: hidden;

        h1 {
          position: relative;
          color: $c-secondary;
          font-size: 6.6rem;
          margin-left: -600px;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-quaternary;
    z-index: 1;
  }

  .overlay.primary {
    background-color: $c-primary;
  }

  .img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-light-grey;
    z-index: 1;
  }

  .tres-trois-rivieres {
    position: absolute;
    bottom: 3.2rem;
    right: -3.2rem;
    z-index: 2;
    width: 12.4rem;
    opacity: 0;
    visibility: hidden;
  }

  @media (max-width: $q-desktop - 1) {
    .tres-trois-rivieres {
      right: 0;
    }
  }

}

.copyright {
  @include fluid-paddingY($from-small, $to-enormous, $unit-small/2, $unit/2.5);
  @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit*2);
  color: $c-secondary;

  .cinetic {
    vertical-align: text-bottom;
    width: 100px;
  }

  .copyright-content_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media("<sm") {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .copyright-content {
        &:not(:last-child) {
          margin-bottom: $unit-small/2;
        }
      }
    }
  }
}



/* non-responsive
————————————————————————————————————————————————————————————————————————————— */
@media (max-width: $q-desktop - 1) {

  .footer {
    .footer-img {
      display: none;
    }

    .content-wrap {
      .content {
        padding: 6.4rem;
      }
    }
  }
}

@media (max-width: $q-phablet - 1) {

  .footer {
    .content-wrap {
      .content {
        padding: 3.2rem;

        .txt-wrap {
          h1 {
            font-size: 4.4rem;
          }
        }

        .div-64 {
          height: 3.2rem;
        }
      }
    }
  }

}