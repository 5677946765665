// ==========================================================================
// Fluid Padding
// ==========================================================================

@function strip-unit($value-padding) {
    @return $value-padding / ($value-padding * 0 + 1);
}

@mixin fluid-padding($min-vw, $max-vw, $min-padding, $max-padding) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-padding);
    $u4: unit($max-padding);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding: $min-padding;

            @media screen and (min-width: $min-vw) {
                padding: calc(#{$min-padding} + #{strip-unit($max-padding - $min-padding)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding: $max-padding;
            }
        }
    }
}

// example
// @include fluid-padding($from-small, $to-huge, $unit, $unit-large);

// ==========================================================================
// Fluid Padding X
// ==========================================================================

@function strip-unit($value-paddingX) {
    @return $value-paddingX / ($value-paddingX * 0 + 1);
}

@mixin fluid-paddingX($min-vw, $max-vw, $min-paddingX, $max-paddingX) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingX);
    $u4: unit($max-paddingX);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-left: $min-paddingX;
            padding-right: $min-paddingX;

            @media screen and (min-width: $min-vw) {
                padding-left: calc(#{$min-paddingX} + #{strip-unit($max-paddingX - $min-paddingX)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                padding-right: calc(#{$min-paddingX} + #{strip-unit($max-paddingX - $min-paddingX)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-left: $max-paddingX;
                padding-right: $max-paddingX;
            }
        }
    }
}

// ==========================================================================
// Fluid Padding Y
// ==========================================================================

@function strip-unit($value-paddingY) {
    @return $value-paddingY / ($value-paddingY * 0 + 1);
}

@mixin fluid-paddingY($min-vw, $max-vw, $min-paddingY, $max-paddingY) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingY);
    $u4: unit($max-paddingY);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-top: $min-paddingY;
            padding-bottom: $min-paddingY;

            @media screen and (min-width: $min-vw) {
                padding-top: calc(#{$min-paddingY} + #{strip-unit($max-paddingY - $min-paddingY)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                padding-bottom: calc(#{$min-paddingY} + #{strip-unit($max-paddingY - $min-paddingY)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-top: $max-paddingY;
                padding-bottom: $max-paddingY;
            }
        }
    }
}

// ==========================================================================
// Fluid Padding Top
// ==========================================================================

@function strip-unit($value-paddingTop) {
    @return $value-paddingTop / ($value-paddingTop * 0 + 1);
}

@mixin fluid-paddingTop($min-vw, $max-vw, $min-paddingTop, $max-paddingTop) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingTop);
    $u4: unit($max-paddingTop);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-top: $min-paddingTop;

            @media screen and (min-width: $min-vw) {
                padding-top: calc(#{$min-paddingTop} + #{strip-unit($max-paddingTop - $min-paddingTop)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-top: $max-paddingTop;
            }
        }
    }
}

// ==========================================================================
// Fluid Padding Bottom
// ==========================================================================

@function strip-unit($value-paddingBottom) {
    @return $value-paddingBottom / ($value-paddingBottom * 0 + 1);
}

@mixin fluid-paddingBottom($min-vw, $max-vw, $min-paddingBottom, $max-paddingBottom) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingBottom);
    $u4: unit($max-paddingBottom);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-bottom: $min-paddingBottom;

            @media screen and (min-width: $min-vw) {
                padding-bottom: calc(#{$min-paddingBottom} + #{strip-unit($max-paddingBottom - $min-paddingBottom)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-bottom: $max-paddingBottom;
            }
        }
    }
}

// ==========================================================================
// Fluid Padding Left
// ==========================================================================

@function strip-unit($value-paddingLeft) {
    @return $value-paddingLeft / ($value-paddingLeft * 0 + 1);
}

@mixin fluid-paddingLeft($min-vw, $max-vw, $min-paddingLeft, $max-paddingLeft) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingLeft);
    $u4: unit($max-paddingLeft);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-left: $min-paddingLeft;

            @media screen and (min-width: $min-vw) {
                padding-left: calc(#{$min-paddingLeft} + #{strip-unit($max-paddingLeft - $min-paddingLeft)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-left: $max-paddingLeft;
            }
        }
    }
}

// ==========================================================================
// Fluid Padding Right
// ==========================================================================

@function strip-unit($value-paddingRight) {
    @return $value-paddingRight / ($value-paddingRight * 0 + 1);
}

@mixin fluid-paddingRight($min-vw, $max-vw, $min-paddingRight, $max-paddingRight) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-paddingRight);
    $u4: unit($max-paddingRight);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            padding-right: $min-paddingRight;

            @media screen and (min-width: $min-vw) {
                padding-right: calc(#{$min-paddingRight} + #{strip-unit($max-paddingRight - $min-paddingRight)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                padding-right: $max-paddingRight;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin
// ==========================================================================

@function strip-unit($value-margin) {
    @return $value-margin / ($value-margin * 0 + 1);
}

@mixin fluid-margin($min-vw, $max-vw, $min-margin, $max-margin) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-margin);
    $u4: unit($max-margin);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin: $min-margin;

            @media screen and (min-width: $min-vw) {
                margin: calc(#{$min-margin} + #{strip-unit($max-margin - $min-margin)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin: $max-margin;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin X
// ==========================================================================

@function strip-unit($value-marginX) {
    @return $value-marginX / ($value-marginX * 0 + 1);
}

@mixin fluid-marginX($min-vw, $max-vw, $min-marginX, $max-marginX) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginX);
    $u4: unit($max-marginX);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-left: $min-marginX;
            margin-right: $min-marginX;

            @media screen and (min-width: $min-vw) {
                margin-left: calc(#{$min-marginX} + #{strip-unit($max-marginX - $min-marginX)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                margin-right: calc(#{$min-marginX} + #{strip-unit($max-marginX - $min-marginX)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-left: $max-marginX;
                margin-right: $max-marginX;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin Y
// ==========================================================================

@function strip-unit($value-marginY) {
    @return $value-marginY / ($value-marginY * 0 + 1);
}

@mixin fluid-marginY($min-vw, $max-vw, $min-marginY, $max-marginY) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginY);
    $u4: unit($max-marginY);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-top: $min-marginY;
            margin-bottom: $min-marginY;

            @media screen and (min-width: $min-vw) {
                margin-top: calc(#{$min-marginY} + #{strip-unit($max-marginY - $min-marginY)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                margin-bottom: calc(#{$min-marginY} + #{strip-unit($max-marginY - $min-marginY)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-top: $max-marginY;
                margin-bottom: $max-marginY;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin Top
// ==========================================================================

@function strip-unit($value-marginTop) {
    @return $value-marginTop / ($value-marginTop * 0 + 1);
}

@mixin fluid-marginTop($min-vw, $max-vw, $min-marginTop, $max-marginTop) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginTop);
    $u4: unit($max-marginTop);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-top: $min-marginTop;

            @media screen and (min-width: $min-vw) {
                margin-top: calc(#{$min-marginTop} + #{strip-unit($max-marginTop - $min-marginTop)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-top: $max-marginTop;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin Bottom
// ==========================================================================

@function strip-unit($value-marginBottom) {
    @return $value-marginBottom / ($value-marginBottom * 0 + 1);
}

@mixin fluid-marginBottom($min-vw, $max-vw, $min-marginBottom, $max-marginBottom) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginBottom);
    $u4: unit($max-marginBottom);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-bottom: $min-marginBottom;

            @media screen and (min-width: $min-vw) {
                margin-bottom: calc(#{$min-marginBottom} + #{strip-unit($max-marginBottom - $min-marginBottom)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-bottom: $max-marginBottom;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin Left
// ==========================================================================

@function strip-unit($value-marginLeft) {
    @return $value-marginLeft / ($value-marginLeft * 0 + 1);
}

@mixin fluid-marginLeft($min-vw, $max-vw, $min-marginLeft, $max-marginLeft) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginLeft);
    $u4: unit($max-marginLeft);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-left: $min-marginLeft;

            @media screen and (min-width: $min-vw) {
                margin-left: calc(#{$min-marginLeft} + #{strip-unit($max-marginLeft - $min-marginLeft)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-left: $max-marginLeft;
            }
        }
    }
}

// ==========================================================================
// Fluid Margin Right
// ==========================================================================

@function strip-unit($value-marginRight) {
    @return $value-marginRight / ($value-marginRight * 0 + 1);
}

@mixin fluid-marginRight($min-vw, $max-vw, $min-marginRight, $max-marginRight) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-marginRight);
    $u4: unit($max-marginRight);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            margin-right: $min-marginRight;

            @media screen and (min-width: $min-vw) {
                margin-right: calc(#{$min-marginRight} + #{strip-unit($max-marginRight - $min-marginRight)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                margin-right: $max-marginRight;
            }
        }
    }
}

// ==========================================================================
// Fluid Width
// ==========================================================================

@function strip-unit($value-width) {
    @return $value-width / ($value-width * 0 + 1);
}

@mixin fluid-width($min-vw, $max-vw, $min-width, $max-width) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-width);
    $u4: unit($max-width);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            width: $min-width;

            @media screen and (min-width: $min-vw) {
                width: calc(#{$min-width} + #{strip-unit($max-width - $min-width)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                width: $max-width;
            }
        }
    }
}

@function strip-unit($value-minWidth) {
    @return $value-minWidth / ($value-minWidth * 0 + 1);
}

@mixin fluid-minWidth($min-vw, $max-vw, $min-minWidth, $max-minWidth) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-minWidth);
    $u4: unit($max-minWidth);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            min-width: $min-minWidth;

            @media screen and (min-minWidth: $min-vw) {
                min-width: calc(#{$min-minWidth} + #{strip-unit($max-minWidth - $min-minWidth)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-minWidth: $max-vw) {
                min-width: $max-minWidth;
            }
        }
    }
}

// ==========================================================================
// Fluid Height
// ==========================================================================

@function strip-unit($value-height) {
    @return $value-height / ($value-height * 0 + 1);
}

@mixin fluid-height($min-vw, $max-vw, $min-height, $max-height) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-height);
    $u4: unit($max-height);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            height: $min-height;

            @media screen and (min-width: $min-vw) {
                height: calc(#{$min-height} + #{strip-unit($max-height - $min-height)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                height: $max-height;
            }
        }
    }
}

@function strip-unit($value-minHeight) {
    @return $value-minHeight / ($value-minHeight * 0 + 1);
}

@mixin fluid-minHeight($min-vw, $max-vw, $min-minHeight, $max-minHeight) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-minHeight);
    $u4: unit($max-minHeight);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            min-height: $min-minHeight;

            @media screen and (min-width: $min-vw) {
                min-height: calc(#{$min-minHeight} + #{strip-unit($max-minHeight - $min-minHeight)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                min-height: $max-minHeight;
            }
        }
    }
}

// ==========================================================================
// Fluid Top
// ==========================================================================

@function strip-unit($value-top) {
    @return $value-top / ($value-top * 0 + 1);
}

@mixin fluid-top($min-vw, $max-vw, $min-top, $max-top) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-top);
    $u4: unit($max-top);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            top: $min-top;

            @media screen and (min-width: $min-vw) {
                top: calc(#{$min-top} + #{strip-unit($max-top - $min-top)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                top: $max-top;
            }
        }
    }
}

// ==========================================================================
// Fluid Bottom
// ==========================================================================

@function strip-unit($value-bottom) {
    @return $value-bottom / ($value-bottom * 0 + 1);
}

@mixin fluid-bottom($min-vw, $max-vw, $min-bottom, $max-bottom) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-bottom);
    $u4: unit($max-bottom);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            bottom: $min-bottom;

            @media screen and (min-width: $min-vw) {
                bottom: calc(#{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                bottom: $max-bottom;
            }
        }
    }
}

// ==========================================================================
// Fluid Left
// ==========================================================================

@function strip-unit($value-left) {
    @return $value-left / ($value-left * 0 + 1);
}

@mixin fluid-left($min-vw, $max-vw, $min-left, $max-left) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-left);
    $u4: unit($max-left);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            left: $min-left;

            @media screen and (min-width: $min-vw) {
                left: calc(#{$min-left} + #{strip-unit($max-left - $min-left)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                left: $max-left;
            }
        }
    }
}

// ==========================================================================
// Fluid Right
// ==========================================================================

@function strip-unit($value-right) {
    @return $value-right / ($value-right * 0 + 1);
}

@mixin fluid-right($min-vw, $max-vw, $min-right, $max-right) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-right);
    $u4: unit($max-right);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {

            right: $min-right;

            @media screen and (min-width: $min-vw) {
                right: calc(#{$min-right} + #{strip-unit($max-right - $min-right)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                right: $max-right;
            }
        }
    }
}