// main: ../main.scss

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  height: 100vh;
  z-index: 4000;
  opacity: 0;
  visibility: hidden;
  display: none;

  .video-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    background-color: #eee;
    opacity: 0.9;
  }

  iframe {
    width: 85vw;
    height: 48vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .video-popup-close {
    z-index: 2;
    top: 32px;
    right: 32px;
    position: absolute;
    -webkit-transform: scale(0);
            transform: scale(0);
    svg {
      width: 32px;
      height: 32px;
      color: $c-primary;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .c-video-popup_video {
    width: 85vw;
    height: 48vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.85);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
  }
}
