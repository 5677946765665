.o-section-search {
	.c-form_item {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.o-search-value {
		color: $link-color;
	}
	.o-search-result {
		border-bottom: 1px solid $black;
		margin-bottom: $unit-small;
		padding-bottom: $unit-small;
	}
}

.c-paging-navigation {
	.page-numbers {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		a, span {
			padding: $unit-small/4;
			line-height: 1;
		}
	}
}