.u-push-1\/2 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/3 {
    position: relative !important;
    right: auto !important;
    margin-left: 33.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/4 {
    position: relative !important;
    right: auto !important;
    margin-left: 25% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/5 {
    position: relative !important;
    right: auto !important;
    margin-left: 20% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/6 {
    position: relative !important;
    right: auto !important;
    margin-left: 16.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 14.28571% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 14.28571% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 12.5% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 12.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 11.11111% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 11.11111% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 10% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 10% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 9.09091% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 9.09091% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-1\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 8.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 8.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/3 {
    position: relative !important;
    right: auto !important;
    margin-left: 66.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/4 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/5 {
    position: relative !important;
    right: auto !important;
    margin-left: 40% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/6 {
    position: relative !important;
    right: auto !important;
    margin-left: 33.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 28.57143% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 28.57143% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 25% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 22.22222% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 22.22222% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 20% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 20% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 18.18182% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 18.18182% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-2\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 16.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 16.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/4 {
    position: relative !important;
    right: auto !important;
    margin-left: 75% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/5 {
    position: relative !important;
    right: auto !important;
    margin-left: 60% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/6 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 42.85714% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 42.85714% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 37.5% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 37.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 33.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 30% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 30% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 27.27273% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 27.27273% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-3\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 25% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/5 {
    position: relative !important;
    right: auto !important;
    margin-left: 80% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/6 {
    position: relative !important;
    right: auto !important;
    margin-left: 66.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 57.14286% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 57.14286% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 44.44444% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 44.44444% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 40% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 40% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 36.36364% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 36.36364% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-4\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 33.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/6 {
    position: relative !important;
    right: auto !important;
    margin-left: 83.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 71.42857% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 71.42857% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 62.5% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 62.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 55.55556% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 55.55556% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 45.45455% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 45.45455% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-5\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 41.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 41.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/7 {
    position: relative !important;
    right: auto !important;
    margin-left: 85.71429% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 85.71429% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 75% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 66.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 60% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 60% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 54.54545% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 54.54545% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-6\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 50% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-7\/8 {
    position: relative !important;
    right: auto !important;
    margin-left: 87.5% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 87.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-7\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 77.77778% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 77.77778% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-7\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 70% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 70% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-7\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 63.63636% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 63.63636% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-7\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 58.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 58.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-8\/9 {
    position: relative !important;
    right: auto !important;
    margin-left: 88.88889% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 88.88889% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-8\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 80% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 80% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-8\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 72.72727% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 72.72727% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-8\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 66.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-9\/10 {
    position: relative !important;
    right: auto !important;
    margin-left: 90% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 90% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-9\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 81.81818% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 81.81818% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-9\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 75% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-10\/11 {
    position: relative !important;
    right: auto !important;
    margin-left: 90.90909% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 90.90909% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-10\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 83.33333% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 83.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-push-11\/12 {
    position: relative !important;
    right: auto !important;
    margin-left: 91.66667% !important;

    &\@from-small {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        right: auto !important;
        margin-left: 91.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

//

.u-pull-1\/2 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/3 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -33.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/4 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -25% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/5 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -20% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/6 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -16.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -14.28571% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -14.28571% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -12.5% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -12.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -11.11111% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -11.11111% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -10% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -10% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -9.09091% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -9.09091% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-1\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -8.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -8.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/3 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -66.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/4 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/5 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -40% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/6 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -33.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -28.57143% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -28.57143% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -25% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -22.22222% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -22.22222% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -20% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -20% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -18.18182% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -18.18182% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-2\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -16.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -16.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/4 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -75% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/5 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -60% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/6 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -42.85714% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -42.85714% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -37.5% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -37.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -33.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -30% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -30% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -27.27273% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -27.27273% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-3\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -25% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -25% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/5 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -80% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/6 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -66.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -57.14286% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -57.14286% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -44.44444% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -44.44444% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -40% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -40% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -36.36364% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -36.36364% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-4\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -33.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -33.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/6 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -83.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -71.42857% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -71.42857% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -62.5% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -62.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -55.55556% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -55.55556% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -45.45455% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -45.45455% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-5\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -41.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -41.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/7 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -85.71429% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -85.71429% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -75% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -66.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -60% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -60% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -54.54545% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -54.54545% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-6\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -50% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -50% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-7\/8 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -87.5% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -87.5% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-7\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -77.77778% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -77.77778% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-7\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -70% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -70% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-7\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -63.63636% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -63.63636% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-7\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -58.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -58.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-8\/9 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -88.88889% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -88.88889% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-8\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -80% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -80% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-8\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -72.72727% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -72.72727% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-8\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -66.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -66.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-9\/10 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -90% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-9\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -81.81818% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -81.81818% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-9\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -75% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -75% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-10\/11 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -90.90909% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -90.90909% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-10\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -83.33333% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -83.33333% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}

.u-pull-11\/12 {
    position: relative !important;
    margin-right: auto !important;
    margin-left: -91.66667% !important;

    &\@from-small {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-small) {
            margin-left: 0 !important;
        }
    }

    &\@from-medium {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-medium) {
            margin-left: 0 !important;
        }
    }

    &\@from-large {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-large) {
            margin-left: 0 !important;
        }
    }

    &\@from-big {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-big) {
            margin-left: 0 !important;
        }
    }

    &\@from-huge {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-huge) {
            margin-left: 0 !important;
        }
    }

    &\@from-enormous {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-enormous) {
            margin-left: 0 !important;
        }
    }

    &\@from-gigantic {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-gigantic) {
            margin-left: 0 !important;
        }
    }

    &\@from-colossal {
        position: relative !important;
        margin-right: auto !important;
        margin-left: -91.66667% !important;

        @media (max-width: $to-colossal) {
            margin-left: 0 !important;
        }
    }
}