.c-header {
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: 0 0 50px 0 rgba($black, 0.1);

	.c-header_nav {
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		@include fluid-padding($from-small, $to-enormous, $unit-small/2, $unit/2);

		.c-header_nav_list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include fluid-marginRight($from-small, $to-enormous, -$unit-small/4, -$unit/4);

			.c-nav_item {
				.c-header_nav_link {
					@include fluid-padding($from-small, $to-enormous, $unit-small/4, $unit/4);
				}
			}
		}

		.c-header_nav_search-button {
			svg {
				color: $primary;
				width: 24px;
				height: 24px;
			}
		}
	}
}

header {
	svg {
		fill: none;
	}
}