h1,
.u-h1,
h2,
.u-h2,
h3,
.u-h3,
h4,
.u-h4,
h5,
.u-h5,
h6,
.u-h6 {
    font-family: $font-h;
    color: $black;
    line-height: $line-height-h/1.25;
}

h1,
.u-h1 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h1, $max-font-size-h1);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

h2,
.u-h2 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h2, $max-font-size-h2);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

h3,
.u-h3 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h3, $max-font-size-h3);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

h4,
.u-h4 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h4, $max-font-size-h4);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

h5,
.u-h5 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h5, $max-font-size-h5);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

h6,
.u-h6 {
    @include fluid-type($from-small, $to-enormous, $min-font-size-h6, $max-font-size-h6);
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
}

p,
.u-p {
    @include fluid-type($from-small, $to-enormous, $min-font-size-p, $max-font-size-p);
    font-family: $font-p;
    line-height: $line-height;
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);

    &:last-child {
        margin-bottom: 0;
    }
}