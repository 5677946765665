// main: ../main.scss

.open-section {
  position: relative;

  @include media("<md") {
    padding-top: $unit-small;
  }

  h1 {
    color: $c-secondary;
  }

  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $c-secondary;
    z-index: 0;
  }

  .padding {
    opacity: 0;
    visibility: hidden;
  }

  .display {
    color: $c-tertiary;
    font-size: 9.6rem;
  }

  p {
    color: $c-white;
  }

  .statement {
    padding: 6.4rem;
    color: $c-secondary;

    .bg-color {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      background-color: $c-quaternary;
      z-index: 0;
    }

    .content-wrap {
      position: relative;
      z-index: 1;

      .overflow {
        overflow: hidden;
      }

      .list-wrap {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);

        .number-list {
          font-weight: 800;
          margin-bottom: 0.8rem;
          display: inline-block;
        }

        .line-list {
          width: 4.8rem;
          height: 2px;
          background-color: $c-secondary;
          display: inline-block;
          margin-left: 0.8rem;
        }

        h1 {
          line-height: 1;

          &:last-child {
            margin-bottom: 1.5%;
          }
        }
        @media (max-width: 1650px) {
          h1 {
            font-size: 32px;
          }
        }

        @media (max-width: 1290px) {
          h1 {
            font-size: 24px;
          }
        }

        @media (max-width: 1090px) {
          h1 {
            font-size: 20px;
          }
        }

        @media (max-width: 999px) {
          h1 {
            font-size: 40px;
          }
        }

        .offset {
          margin-left: 6.4rem;
        }
      }
    }
  }
}

.call-to-action {
  position: relative;
  z-index: 2;
  .small-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-style: italic;
    color: $c-secondary;
    width: 100%;
  }

  h3,
  p {
    margin-bottom: 0;
  }

  .button-wrap {
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    z-index: 2;
    left: 50%;

    .button {
      z-index: 3;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      .stroke {
        border: solid 0.2rem $c-quaternary;
        background-color: $c-quaternary;
      }

      .icon,
      .txt {
        color: $c-secondary;
      }

      .overlay1 {
        background-color: $c-quaternary;
      }

      .overlay2 {
        background-color: $c-tertiary;
      }
    }
  }

  .line-full {
    width: 83.333334%;
    height: 1px;
    background-color: $c-tertiary;
    margin-left: 8.333333%;
  }
}

.reasons-section {
  position: relative;
  color: $c-white;
  z-index: 3;

  .bg-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-secondary;
    z-index: 0;
  }

  .button {
    .stroke {
      border: solid 0.2rem $c-tertiary;
      background-color: $c-tertiary;
    }

    .icon,
    .txt {
      color: $c-white;
    }

    .overlay1 {
      background-color: $c-primary;
    }

    .overlay2 {
      background-color: $c-secondary;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 4.5rem;

    li {
      margin-bottom: 3.2rem;
      font-size: 1.8rem;
      line-height: 3.2rem;
    }

    li:before {
      content: "";
      display: block;
      position: absolute;
      width: 2.4rem;
      height: 0.2rem;
      background-color: $c-tertiary;
      margin-left: -42px;
      margin-top: 14px;
    }
  }
}

.reasons-section.alt {
  color: $c-black;
  margin-top: -96px;
  z-index: 3;

  .bg-color {
    background-color: $c-white;
  }
}

.img-separator {
  position: relative;
  margin-top: -96px;

  .bg-img {
    position: absolute;
    top: -4%;
    bottom: -4%;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media (max-width: $q-desktop - 1) {
  .img-separator {
    margin-top: 0;
  }
}

.team-section {
  .title {
    position: relative;

    h1 {
      position: absolute;
      top: -220px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      font-size: 15.6rem;
      opacity: 0.08;
      letter-spacing: -0.78rem;
    }
  }

  .member {
    .first-img {
      position: absolute;
      -webkit-transition: all ease-out 150ms;
      transition: all ease-out 150ms;
    }
  }

  .member:hover {
    .first-img {
      opacity: 0;
    }
  }
}
/* Responsive
————————————————————————————————————————————————————————————————————————————— */
@media (max-width: $q-desktop - 1) {
  .call-to-action {
    .content-wrap {
      padding: 3.2rem;
    }

    .small-cta {
      min-height: 10rem;
    }
  }
}
@media (max-width: $q-phablet - 1) {}
/* Video */
.video-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  video {
    position: absolute;
    top: -15%;
    bottom: -15%;
    left: 0;
    right: 0;
    width: 100%;
    height: 130%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
  }
}

.bg-color-position {
  margin-left: -8.333333vw;
  margin-right: 8.333333vw;
  opacity: 0.8;
}
@media (max-width: 999px) {
  .header-section .header-content .bg-color {
    top: -64px;
    left: -64px;
    bottom: -64px;
    right: calc(100% + 64px);
  }

  .bg-color-position {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 549px) {
  .header-section .header-content .bg-color {
    top: -32px;
    left: -32px;
    bottom: -32px;
    right: calc(100% + 32px);
  }
}

.bg-color-opacity {
  opacity: 0.92;
}

.play-svg-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  width: 100px;
  height: 100px;

  svg {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: calc(50% + 4px);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .stroke {
    border: solid 4px #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
  }

  &:hover {
    cursor: pointer;
  }
}

.play-bg-1 {
  width: 128px;
  height: 128px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.play-bg-2 {
  width: 128px;
  height: 128px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.video-reveal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
}

.play-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
@media (max-width: 1000px) {
  .play-btn {
    position: relative;
    top: unset;
    bottom: unset;
    left: -16px;
    right: unset;
    height: 300px;
    width: calc(100vw - 96px);
  }
}
@media (max-width: 549px) {
  .play-btn {
    position: relative;
    top: unset;
    bottom: unset;
    left: -16px;
    right: unset;
    height: 300px;
    width: calc(100vw - 32px);
  }
}

@media (max-width: 1650px) {
.open-section .statement .content-wrap .list-wrap h1 {
    font-size: 32px;
}
}

@media (max-width: 1290px) {
.open-section .statement .content-wrap .list-wrap h1 {
    font-size: 24px;
}
}

@media (max-width: 1090px) {
.open-section .statement .content-wrap .list-wrap h1 {
    font-size: 20px;
}
}

@media (max-width: 999px) {
.open-section .statement .content-wrap .list-wrap h1 {
    font-size: 40px;
}
}

@media (max-width: 480px) {
.open-section .statement .content-wrap .list-wrap h1 {
    font-size: 18px;
}
}

@media (max-width: 999px) {
.title h1 {
    font-size: 10.6rem!important;
}
.display {
    font-size: 4rem!important;
}
}

.o-section-content {
  img {
    @include media("<md") {
      margin-bottom: 96px;
    }
  }
}