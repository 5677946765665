// ==========================================================================
// Objects / Container
// ==========================================================================

//
// Page-level constraining and wrapping elements.
//
// > In programming languages the word *container* is generally used for structures
// that can contain more than one element.
// > A *wrapper* instead is something that wraps around a single object to provide
// more functionalities and interfaces to it.
// @link http://stackoverflow.com/a/13202141/140357
//

.o-container {
    margin-right: auto;
    margin-left: auto;
    @include fluid-paddingX($from-small, $to-enormous, $unit-small/2, $unit*2);
    max-width: rem($container-width + ($padding * 2));
}
