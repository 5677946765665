// main: ../main.scss

nav {
  .menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -200vw;
    right: 0;
    pointer-events: auto;
    min-height: 100vh;
    z-index: -2;
    overflow-y: auto;
    overflow-x: hidden;
    width: 0;

    .menu-bg1 {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100vw;
      background-color: rgba($c-light-grey, 0.96);
      min-height: 100%;
      width: 0;
    }

    .menu-bg2 {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100vw;
      background-color: $c-primary;
      min-height: 100%;
      width: 0;
    }

    .column,
    .columns {
      position: relative;
      min-height: 100vh;
      display: flex;
      align-items: center;
    }

    .menu-list {
      position: relative;
      list-style-type: none;
      font-size: 4.8rem;
      // top: 50%;
      // -webkit-transform: translateY(-50%);
      // transform: translateY(-50%);
      margin: 0;
      padding: 0;

      transition: opacity $speed $easing $speed/2, visibility $speed $easing $speed/2, transform $speed $easing $speed/2;

      &.-sub-nav-open {
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity $speed $easing, visibility $speed $easing, transform $speed $easing;
      }

      li {
        position: relative;
        margin-bottom: 0;
        overflow: hidden;
      }

      a,
      span {
        position: relative;
        display: inline-block;
        color: $c-black;
        padding: 0.8rem 0;
        cursor: pointer;

        &:hover {
          svg {
            transform: translateX(8px);
          }
        }

        svg {
          transition: transform $speed $easing;
        }
      }

      .menu-line {
        position: absolute;
        left: 0;
        right: 100%;
        height: 0.2rem;
        background-color: $c-primary;
      }

      .menu-line.hover {
        position: absolute;
        left: 0;
        right: 100%;
        height: 0.2rem;
        background-color: $c-primary;
      }
    }

    .menu-contact {
      position: relative;
      // top: 50%;
      // -webkit-transform: translateY(-50%);
      // transform: translateY(-50%);

      .content-wrap {
        overflow: hidden;
        visibility: hidden;
        opacity: 0;

        a {
          color: $c-primary;
          -webkit-transition: all ease 320ms;
          transition: all ease 320ms;
        }

        a:hover {
          color: $c-secondary;
        }
      }
    }
  }
}

.sub-menu-list {
  position: absolute;
  top: 50%;
  left: 0;
  right: 25%;
  transform: translate(50%, -50%);
  opacity: 0;
  visibility: hidden;

  transition: opacity $speed $easing, visibility $speed $easing, transform $speed $easing;

  @media (max-width: $q-desktop - 1) {
    padding: 3.2rem !important;
    li {
      a {
        font-size: 2.0rem !important;
      }
    }
  }

  li {
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/3, $unit-small/2);
    a {
      color: $c-black;
      font-size: 2.8rem;

      transition: color $speed $easing;

      &:hover {
        color: $c-primary;
      }
    }
  }

  .-back {
    @include fluid-marginBottom($from-small, $to-enormous, $unit-small/2, $unit-small);
    cursor: pointer;
    display: inline-block;

    &:hover {
      svg {
        transform: translateX(-5px);
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-bottom: 3px;

      transition: transform $speed $easing;
    }
  }

  &.-sub-nav-open {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
    transition: opacity $speed $easing $speed/2, visibility $speed $easing $speed/2, transform $speed $easing $speed/2;
  }
}