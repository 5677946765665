// main: ../main.scss

.div-16 {
  display: block;
  height: 1.6rem;
}

.div-24 {
  display: block;
  height: 2.4rem;
}

.div-32 {
  display: block;
  height: 3.2rem;
}

.div-64 {
  display: block;
  height: 6.4rem;
}

.div-96 {
  display: block;
  height: 9.6rem;
}

.div-128 {
  display: block;
  height: 12.8rem;
}

.div-160 {
  display: block;
  height: 16.0rem;
}

.div-256 {
  display: block;
  height: 25.6rem;
}

.div-416 {
  display: block;
  height: 41.6rem;
}

.div-832 {
  display: block;
  height: 83.2rem;
}

.line-48 {
  width: 4.8rem;
  height: 0.2rem;
  background: $c-secondary;
}

@media (max-width: $q-desktop - 1) {

  .div-64 {
    height: 4.8rem !important;
  }

  .div-96 {
    height: 6.4rem !important;
  }

  .div-128 {
    height: 9.6rem !important;
  }

  .div-160 {
    height: 12.8rem !important;
  }
}

@media (max-width: $q-phablet - 1) {

  .div-64 {
    height: 3.2rem !important;
  }

  .div-96 {
    height: 4.8rem !important;
  }

  .div-128 {
    height: 6.4rem !important;
  }

  .div-160 {
    height: 9.6rem !important;
  }
}
