// ==========================================================================
// Utilities / Widths
// ==========================================================================

////
/// @link https://github.com/inuitcss/inuitcss/blob/6eb574f/utilities/_utilities.widths.scss
////

/// Which fractions would you like in your grid system(s)?
/// By default, the boilerplate provides fractions of one whole, halves, thirds,
/// quarters, and fifths, e.g.:
///
/// @example css
///   .u-1/2
///   .u-2/5
///   .u-3/4
///   .u-2/3
$widths-fractions: 1 2 3 4 5 6 7 8 9 10 11 12 !default;

@include widths($widths-fractions);

.u-1\/1\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/1);
    }
}

.u-1\/2\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/2);
    }
}

.u-1\/3\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/3);
    }
}

.u-1\/4\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/4);
    }
}

.u-1\/5\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/5);
    }
}

.u-1\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/6);
    }
}

.u-1\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/7);
    }
}

.u-1\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/8);
    }
}

.u-1\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/9);
    }
}

.u-1\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/10);
    }
}

.u-1\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/11);
    }
}

.u-1\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(1/12);
    }
}

.u-2\/2\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/2);
    }
}

.u-2\/3\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/3);
    }
}

.u-2\/4\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/4);
    }
}

.u-2\/5\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/5);
    }
}

.u-2\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/6);
    }
}

.u-2\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/7);
    }
}

.u-2\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/8);
    }
}

.u-2\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/9);
    }
}

.u-2\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/10);
    }
}

.u-2\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/11);
    }
}

.u-2\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(2/12);
    }
}

.u-3\/3\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/3);
    }
}

.u-3\/4\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/4);
    }
}

.u-3\/5\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/5);
    }
}

.u-3\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/6);
    }
}

.u-3\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/7);
    }
}

.u-3\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/8);
    }
}

.u-3\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/9);
    }
}

.u-3\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/10);
    }
}

.u-3\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/11);
    }
}

.u-3\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(3/12);
    }
}

.u-4\/4\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/4);
    }
}

.u-4\/5\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/5);
    }
}

.u-4\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/6);
    }
}

.u-4\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/7);
    }
}

.u-4\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/8);
    }
}

.u-4\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/9);
    }
}

.u-4\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/10);
    }
}

.u-4\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/11);
    }
}

.u-4\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(4/12);
    }
}

.u-5\/5\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/5);
    }
}

.u-5\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/6);
    }
}

.u-5\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/7);
    }
}

.u-5\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/8);
    }
}

.u-5\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/9);
    }
}

.u-5\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/10);
    }
}

.u-5\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/11);
    }
}

.u-5\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(5/12);
    }
}

.u-6\/6\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/6);
    }
}

.u-6\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/7);
    }
}

.u-6\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/8);
    }
}

.u-6\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/9);
    }
}

.u-6\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/10);
    }
}

.u-6\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/11);
    }
}

.u-6\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(6/12);
    }
}

.u-7\/7\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/7);
    }
}

.u-7\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/8);
    }
}

.u-7\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/9);
    }
}

.u-7\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/10);
    }
}

.u-7\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/11);
    }
}

.u-7\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(7/12);
    }
}

.u-8\/8\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(8/8);
    }
}

.u-8\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(8/9);
    }
}

.u-8\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(8/10);
    }
}

.u-8\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(8/11);
    }
}

.u-8\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(8/12);
    }
}

.u-9\/9\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(9/9);
    }
}

.u-9\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(9/10);
    }
}

.u-9\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(9/11);
    }
}

.u-9\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(9/12);
    }
}

.u-10\/10\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(10/10);
    }
}

.u-10\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(10/11);
    }
}

.u-10\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(10/12);
    }
}

.u-11\/11\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(11/11);
    }
}

.u-11\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(11/12);
    }
}

.u-12\/12\@from-tiny {
    @media (min-width: $from-tiny) {
        width: span(12/12);
    }
}

.u-1\/1\@from-small {
    @media (min-width: $from-small) {
        width: span(1/1);
    }
}

.u-1\/2\@from-small {
    @media (min-width: $from-small) {
        width: span(1/2);
    }
}

.u-1\/3\@from-small {
    @media (min-width: $from-small) {
        width: span(1/3);
    }
}

.u-1\/4\@from-small {
    @media (min-width: $from-small) {
        width: span(1/4);
    }
}

.u-1\/5\@from-small {
    @media (min-width: $from-small) {
        width: span(1/5);
    }
}

.u-1\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(1/6);
    }
}

.u-1\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(1/7);
    }
}

.u-1\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(1/8);
    }
}

.u-1\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(1/9);
    }
}

.u-1\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(1/10);
    }
}

.u-1\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(1/11);
    }
}

.u-1\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(1/12);
    }
}

.u-2\/2\@from-small {
    @media (min-width: $from-small) {
        width: span(2/2);
    }
}

.u-2\/3\@from-small {
    @media (min-width: $from-small) {
        width: span(2/3);
    }
}

.u-2\/4\@from-small {
    @media (min-width: $from-small) {
        width: span(2/4);
    }
}

.u-2\/5\@from-small {
    @media (min-width: $from-small) {
        width: span(2/5);
    }
}

.u-2\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(2/6);
    }
}

.u-2\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(2/7);
    }
}

.u-2\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(2/8);
    }
}

.u-2\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(2/9);
    }
}

.u-2\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(2/10);
    }
}

.u-2\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(2/11);
    }
}

.u-2\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(2/12);
    }
}

.u-3\/3\@from-small {
    @media (min-width: $from-small) {
        width: span(3/3);
    }
}

.u-3\/4\@from-small {
    @media (min-width: $from-small) {
        width: span(3/4);
    }
}

.u-3\/5\@from-small {
    @media (min-width: $from-small) {
        width: span(3/5);
    }
}

.u-3\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(3/6);
    }
}

.u-3\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(3/7);
    }
}

.u-3\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(3/8);
    }
}

.u-3\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(3/9);
    }
}

.u-3\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(3/10);
    }
}

.u-3\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(3/11);
    }
}

.u-3\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(3/12);
    }
}

.u-4\/4\@from-small {
    @media (min-width: $from-small) {
        width: span(4/4);
    }
}

.u-4\/5\@from-small {
    @media (min-width: $from-small) {
        width: span(4/5);
    }
}

.u-4\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(4/6);
    }
}

.u-4\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(4/7);
    }
}

.u-4\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(4/8);
    }
}

.u-4\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(4/9);
    }
}

.u-4\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(4/10);
    }
}

.u-4\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(4/11);
    }
}

.u-4\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(4/12);
    }
}

.u-5\/5\@from-small {
    @media (min-width: $from-small) {
        width: span(5/5);
    }
}

.u-5\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(5/6);
    }
}

.u-5\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(5/7);
    }
}

.u-5\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(5/8);
    }
}

.u-5\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(5/9);
    }
}

.u-5\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(5/10);
    }
}

.u-5\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(5/11);
    }
}

.u-5\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(5/12);
    }
}

.u-6\/6\@from-small {
    @media (min-width: $from-small) {
        width: span(6/6);
    }
}

.u-6\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(6/7);
    }
}

.u-6\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(6/8);
    }
}

.u-6\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(6/9);
    }
}

.u-6\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(6/10);
    }
}

.u-6\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(6/11);
    }
}

.u-6\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(6/12);
    }
}

.u-7\/7\@from-small {
    @media (min-width: $from-small) {
        width: span(7/7);
    }
}

.u-7\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(7/8);
    }
}

.u-7\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(7/9);
    }
}

.u-7\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(7/10);
    }
}

.u-7\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(7/11);
    }
}

.u-7\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(7/12);
    }
}

.u-8\/8\@from-small {
    @media (min-width: $from-small) {
        width: span(8/8);
    }
}

.u-8\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(8/9);
    }
}

.u-8\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(8/10);
    }
}

.u-8\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(8/11);
    }
}

.u-8\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(8/12);
    }
}

.u-9\/9\@from-small {
    @media (min-width: $from-small) {
        width: span(9/9);
    }
}

.u-9\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(9/10);
    }
}

.u-9\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(9/11);
    }
}

.u-9\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(9/12);
    }
}

.u-10\/10\@from-small {
    @media (min-width: $from-small) {
        width: span(10/10);
    }
}

.u-10\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(10/11);
    }
}

.u-10\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(10/12);
    }
}

.u-11\/11\@from-small {
    @media (min-width: $from-small) {
        width: span(11/11);
    }
}

.u-11\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(11/12);
    }
}

.u-12\/12\@from-small {
    @media (min-width: $from-small) {
        width: span(12/12);
    }
}

.u-1\/1\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/1);
    }
}

.u-1\/2\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/2);
    }
}

.u-1\/3\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/3);
    }
}

.u-1\/4\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/4);
    }
}

.u-1\/5\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/5);
    }
}

.u-1\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/6);
    }
}

.u-1\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/7);
    }
}

.u-1\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/8);
    }
}

.u-1\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/9);
    }
}

.u-1\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/10);
    }
}

.u-1\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/11);
    }
}

.u-1\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(1/12);
    }
}

.u-2\/2\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/2);
    }
}

.u-2\/3\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/3);
    }
}

.u-2\/4\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/4);
    }
}

.u-2\/5\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/5);
    }
}

.u-2\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/6);
    }
}

.u-2\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/7);
    }
}

.u-2\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/8);
    }
}

.u-2\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/9);
    }
}

.u-2\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/10);
    }
}

.u-2\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/11);
    }
}

.u-2\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(2/12);
    }
}

.u-3\/3\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/3);
    }
}

.u-3\/4\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/4);
    }
}

.u-3\/5\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/5);
    }
}

.u-3\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/6);
    }
}

.u-3\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/7);
    }
}

.u-3\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/8);
    }
}

.u-3\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/9);
    }
}

.u-3\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/10);
    }
}

.u-3\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/11);
    }
}

.u-3\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(3/12);
    }
}

.u-4\/4\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/4);
    }
}

.u-4\/5\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/5);
    }
}

.u-4\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/6);
    }
}

.u-4\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/7);
    }
}

.u-4\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/8);
    }
}

.u-4\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/9);
    }
}

.u-4\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/10);
    }
}

.u-4\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/11);
    }
}

.u-4\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(4/12);
    }
}

.u-5\/5\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/5);
    }
}

.u-5\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/6);
    }
}

.u-5\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/7);
    }
}

.u-5\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/8);
    }
}

.u-5\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/9);
    }
}

.u-5\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/10);
    }
}

.u-5\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/11);
    }
}

.u-5\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(5/12);
    }
}

.u-6\/6\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/6);
    }
}

.u-6\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/7);
    }
}

.u-6\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/8);
    }
}

.u-6\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/9);
    }
}

.u-6\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/10);
    }
}

.u-6\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/11);
    }
}

.u-6\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(6/12);
    }
}

.u-7\/7\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/7);
    }
}

.u-7\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/8);
    }
}

.u-7\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/9);
    }
}

.u-7\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/10);
    }
}

.u-7\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/11);
    }
}

.u-7\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(7/12);
    }
}

.u-8\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: span(8/8);
    }
}

.u-8\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(8/9);
    }
}

.u-8\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(8/10);
    }
}

.u-8\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(8/11);
    }
}

.u-8\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(8/12);
    }
}

.u-9\/9\@from-medium {
    @media (min-width: $from-medium) {
        width: span(9/9);
    }
}

.u-9\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(9/10);
    }
}

.u-9\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(9/11);
    }
}

.u-9\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(9/12);
    }
}

.u-10\/10\@from-medium {
    @media (min-width: $from-medium) {
        width: span(10/10);
    }
}

.u-10\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(10/11);
    }
}

.u-10\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(10/12);
    }
}

.u-11\/11\@from-medium {
    @media (min-width: $from-medium) {
        width: span(11/11);
    }
}

.u-11\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(11/12);
    }
}

.u-12\/12\@from-medium {
    @media (min-width: $from-medium) {
        width: span(12/12);
    }
}

.u-1\/1\@from-large {
    @media (min-width: $from-large) {
        width: span(1/1);
    }
}

.u-1\/2\@from-large {
    @media (min-width: $from-large) {
        width: span(1/2);
    }
}

.u-1\/3\@from-large {
    @media (min-width: $from-large) {
        width: span(1/3);
    }
}

.u-1\/4\@from-large {
    @media (min-width: $from-large) {
        width: span(1/4);
    }
}

.u-1\/5\@from-large {
    @media (min-width: $from-large) {
        width: span(1/5);
    }
}

.u-1\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(1/6);
    }
}

.u-1\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(1/7);
    }
}

.u-1\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(1/8);
    }
}

.u-1\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(1/9);
    }
}

.u-1\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(1/10);
    }
}

.u-1\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(1/11);
    }
}

.u-1\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(1/12);
    }
}

.u-2\/2\@from-large {
    @media (min-width: $from-large) {
        width: span(2/2);
    }
}

.u-2\/3\@from-large {
    @media (min-width: $from-large) {
        width: span(2/3);
    }
}

.u-2\/4\@from-large {
    @media (min-width: $from-large) {
        width: span(2/4);
    }
}

.u-2\/5\@from-large {
    @media (min-width: $from-large) {
        width: span(2/5);
    }
}

.u-2\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(2/6);
    }
}

.u-2\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(2/7);
    }
}

.u-2\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(2/8);
    }
}

.u-2\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(2/9);
    }
}

.u-2\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(2/10);
    }
}

.u-2\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(2/11);
    }
}

.u-2\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(2/12);
    }
}

.u-3\/3\@from-large {
    @media (min-width: $from-large) {
        width: span(3/3);
    }
}

.u-3\/4\@from-large {
    @media (min-width: $from-large) {
        width: span(3/4);
    }
}

.u-3\/5\@from-large {
    @media (min-width: $from-large) {
        width: span(3/5);
    }
}

.u-3\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(3/6);
    }
}

.u-3\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(3/7);
    }
}

.u-3\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(3/8);
    }
}

.u-3\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(3/9);
    }
}

.u-3\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(3/10);
    }
}

.u-3\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(3/11);
    }
}

.u-3\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(3/12);
    }
}

.u-4\/4\@from-large {
    @media (min-width: $from-large) {
        width: span(4/4);
    }
}

.u-4\/5\@from-large {
    @media (min-width: $from-large) {
        width: span(4/5);
    }
}

.u-4\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(4/6);
    }
}

.u-4\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(4/7);
    }
}

.u-4\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(4/8);
    }
}

.u-4\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(4/9);
    }
}

.u-4\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(4/10);
    }
}

.u-4\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(4/11);
    }
}

.u-4\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(4/12);
    }
}

.u-5\/5\@from-large {
    @media (min-width: $from-large) {
        width: span(5/5);
    }
}

.u-5\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(5/6);
    }
}

.u-5\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(5/7);
    }
}

.u-5\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(5/8);
    }
}

.u-5\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(5/9);
    }
}

.u-5\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(5/10);
    }
}

.u-5\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(5/11);
    }
}

.u-5\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(5/12);
    }
}

.u-6\/6\@from-large {
    @media (min-width: $from-large) {
        width: span(6/6);
    }
}

.u-6\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(6/7);
    }
}

.u-6\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(6/8);
    }
}

.u-6\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(6/9);
    }
}

.u-6\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(6/10);
    }
}

.u-6\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(6/11);
    }
}

.u-6\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(6/12);
    }
}

.u-7\/7\@from-large {
    @media (min-width: $from-large) {
        width: span(7/7);
    }
}

.u-7\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(7/8);
    }
}

.u-7\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(7/9);
    }
}

.u-7\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(7/10);
    }
}

.u-7\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(7/11);
    }
}

.u-7\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(7/12);
    }
}

.u-8\/8\@from-large {
    @media (min-width: $from-large) {
        width: span(8/8);
    }
}

.u-8\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(8/9);
    }
}

.u-8\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(8/10);
    }
}

.u-8\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(8/11);
    }
}

.u-8\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(8/12);
    }
}

.u-9\/9\@from-large {
    @media (min-width: $from-large) {
        width: span(9/9);
    }
}

.u-9\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(9/10);
    }
}

.u-9\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(9/11);
    }
}

.u-9\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(9/12);
    }
}

.u-10\/10\@from-large {
    @media (min-width: $from-large) {
        width: span(10/10);
    }
}

.u-10\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(10/11);
    }
}

.u-10\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(10/12);
    }
}

.u-11\/11\@from-large {
    @media (min-width: $from-large) {
        width: span(11/11);
    }
}

.u-11\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(11/12);
    }
}

.u-12\/12\@from-large {
    @media (min-width: $from-large) {
        width: span(12/12);
    }
}

.u-1\/1\@from-big {
    @media (min-width: $from-big) {
        width: span(1/1);
    }
}

.u-1\/2\@from-big {
    @media (min-width: $from-big) {
        width: span(1/2);
    }
}

.u-1\/3\@from-big {
    @media (min-width: $from-big) {
        width: span(1/3);
    }
}

.u-1\/4\@from-big {
    @media (min-width: $from-big) {
        width: span(1/4);
    }
}

.u-1\/5\@from-big {
    @media (min-width: $from-big) {
        width: span(1/5);
    }
}

.u-1\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(1/6);
    }
}

.u-1\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(1/7);
    }
}

.u-1\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(1/8);
    }
}

.u-1\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(1/9);
    }
}

.u-1\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(1/10);
    }
}

.u-1\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(1/11);
    }
}

.u-1\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(1/12);
    }
}

.u-2\/2\@from-big {
    @media (min-width: $from-big) {
        width: span(2/2);
    }
}

.u-2\/3\@from-big {
    @media (min-width: $from-big) {
        width: span(2/3);
    }
}

.u-2\/4\@from-big {
    @media (min-width: $from-big) {
        width: span(2/4);
    }
}

.u-2\/5\@from-big {
    @media (min-width: $from-big) {
        width: span(2/5);
    }
}

.u-2\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(2/6);
    }
}

.u-2\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(2/7);
    }
}

.u-2\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(2/8);
    }
}

.u-2\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(2/9);
    }
}

.u-2\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(2/10);
    }
}

.u-2\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(2/11);
    }
}

.u-2\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(2/12);
    }
}

.u-3\/3\@from-big {
    @media (min-width: $from-big) {
        width: span(3/3);
    }
}

.u-3\/4\@from-big {
    @media (min-width: $from-big) {
        width: span(3/4);
    }
}

.u-3\/5\@from-big {
    @media (min-width: $from-big) {
        width: span(3/5);
    }
}

.u-3\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(3/6);
    }
}

.u-3\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(3/7);
    }
}

.u-3\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(3/8);
    }
}

.u-3\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(3/9);
    }
}

.u-3\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(3/10);
    }
}

.u-3\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(3/11);
    }
}

.u-3\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(3/12);
    }
}

.u-4\/4\@from-big {
    @media (min-width: $from-big) {
        width: span(4/4);
    }
}

.u-4\/5\@from-big {
    @media (min-width: $from-big) {
        width: span(4/5);
    }
}

.u-4\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(4/6);
    }
}

.u-4\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(4/7);
    }
}

.u-4\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(4/8);
    }
}

.u-4\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(4/9);
    }
}

.u-4\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(4/10);
    }
}

.u-4\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(4/11);
    }
}

.u-4\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(4/12);
    }
}

.u-5\/5\@from-big {
    @media (min-width: $from-big) {
        width: span(5/5);
    }
}

.u-5\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(5/6);
    }
}

.u-5\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(5/7);
    }
}

.u-5\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(5/8);
    }
}

.u-5\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(5/9);
    }
}

.u-5\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(5/10);
    }
}

.u-5\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(5/11);
    }
}

.u-5\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(5/12);
    }
}

.u-6\/6\@from-big {
    @media (min-width: $from-big) {
        width: span(6/6);
    }
}

.u-6\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(6/7);
    }
}

.u-6\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(6/8);
    }
}

.u-6\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(6/9);
    }
}

.u-6\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(6/10);
    }
}

.u-6\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(6/11);
    }
}

.u-6\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(6/12);
    }
}

.u-7\/7\@from-big {
    @media (min-width: $from-big) {
        width: span(7/7);
    }
}

.u-7\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(7/8);
    }
}

.u-7\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(7/9);
    }
}

.u-7\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(7/10);
    }
}

.u-7\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(7/11);
    }
}

.u-7\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(7/12);
    }
}

.u-8\/8\@from-big {
    @media (min-width: $from-big) {
        width: span(8/8);
    }
}

.u-8\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(8/9);
    }
}

.u-8\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(8/10);
    }
}

.u-8\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(8/11);
    }
}

.u-8\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(8/12);
    }
}

.u-9\/9\@from-big {
    @media (min-width: $from-big) {
        width: span(9/9);
    }
}

.u-9\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(9/10);
    }
}

.u-9\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(9/11);
    }
}

.u-9\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(9/12);
    }
}

.u-10\/10\@from-big {
    @media (min-width: $from-big) {
        width: span(10/10);
    }
}

.u-10\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(10/11);
    }
}

.u-10\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(10/12);
    }
}

.u-11\/11\@from-big {
    @media (min-width: $from-big) {
        width: span(11/11);
    }
}

.u-11\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(11/12);
    }
}

.u-12\/12\@from-big {
    @media (min-width: $from-big) {
        width: span(12/12);
    }
}

.u-1\/1\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/1);
    }
}

.u-1\/2\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/2);
    }
}

.u-1\/3\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/3);
    }
}

.u-1\/4\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/4);
    }
}

.u-1\/5\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/5);
    }
}

.u-1\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/6);
    }
}

.u-1\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/7);
    }
}

.u-1\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/8);
    }
}

.u-1\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/9);
    }
}

.u-1\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/10);
    }
}

.u-1\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/11);
    }
}

.u-1\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(1/12);
    }
}

.u-2\/2\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/2);
    }
}

.u-2\/3\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/3);
    }
}

.u-2\/4\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/4);
    }
}

.u-2\/5\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/5);
    }
}

.u-2\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/6);
    }
}

.u-2\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/7);
    }
}

.u-2\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/8);
    }
}

.u-2\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/9);
    }
}

.u-2\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/10);
    }
}

.u-2\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/11);
    }
}

.u-2\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(2/12);
    }
}

.u-3\/3\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/3);
    }
}

.u-3\/4\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/4);
    }
}

.u-3\/5\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/5);
    }
}

.u-3\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/6);
    }
}

.u-3\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/7);
    }
}

.u-3\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/8);
    }
}

.u-3\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/9);
    }
}

.u-3\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/10);
    }
}

.u-3\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/11);
    }
}

.u-3\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(3/12);
    }
}

.u-4\/4\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/4);
    }
}

.u-4\/5\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/5);
    }
}

.u-4\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/6);
    }
}

.u-4\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/7);
    }
}

.u-4\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/8);
    }
}

.u-4\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/9);
    }
}

.u-4\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/10);
    }
}

.u-4\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/11);
    }
}

.u-4\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(4/12);
    }
}

.u-5\/5\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/5);
    }
}

.u-5\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/6);
    }
}

.u-5\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/7);
    }
}

.u-5\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/8);
    }
}

.u-5\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/9);
    }
}

.u-5\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/10);
    }
}

.u-5\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/11);
    }
}

.u-5\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(5/12);
    }
}

.u-6\/6\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/6);
    }
}

.u-6\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/7);
    }
}

.u-6\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/8);
    }
}

.u-6\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/9);
    }
}

.u-6\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/10);
    }
}

.u-6\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/11);
    }
}

.u-6\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(6/12);
    }
}

.u-7\/7\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/7);
    }
}

.u-7\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/8);
    }
}

.u-7\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/9);
    }
}

.u-7\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/10);
    }
}

.u-7\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/11);
    }
}

.u-7\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(7/12);
    }
}

.u-8\/8\@from-huge {
    @media (min-width: $from-huge) {
        width: span(8/8);
    }
}

.u-8\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(8/9);
    }
}

.u-8\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(8/10);
    }
}

.u-8\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(8/11);
    }
}

.u-8\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(8/12);
    }
}

.u-9\/9\@from-huge {
    @media (min-width: $from-huge) {
        width: span(9/9);
    }
}

.u-9\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(9/10);
    }
}

.u-9\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(9/11);
    }
}

.u-9\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(9/12);
    }
}

.u-10\/10\@from-huge {
    @media (min-width: $from-huge) {
        width: span(10/10);
    }
}

.u-10\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(10/11);
    }
}

.u-10\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(10/12);
    }
}

.u-11\/11\@from-huge {
    @media (min-width: $from-huge) {
        width: span(11/11);
    }
}

.u-11\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(11/12);
    }
}

.u-12\/12\@from-huge {
    @media (min-width: $from-huge) {
        width: span(12/12);
    }
}

.u-1\/1\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/1);
    }
}

.u-1\/2\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/2);
    }
}

.u-1\/3\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/3);
    }
}

.u-1\/4\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/4);
    }
}

.u-1\/5\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/5);
    }
}

.u-1\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/6);
    }
}

.u-1\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/7);
    }
}

.u-1\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/8);
    }
}

.u-1\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/9);
    }
}

.u-1\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/10);
    }
}

.u-1\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/11);
    }
}

.u-1\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(1/12);
    }
}

.u-2\/2\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/2);
    }
}

.u-2\/3\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/3);
    }
}

.u-2\/4\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/4);
    }
}

.u-2\/5\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/5);
    }
}

.u-2\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/6);
    }
}

.u-2\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/7);
    }
}

.u-2\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/8);
    }
}

.u-2\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/9);
    }
}

.u-2\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/10);
    }
}

.u-2\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/11);
    }
}

.u-2\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(2/12);
    }
}

.u-3\/3\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/3);
    }
}

.u-3\/4\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/4);
    }
}

.u-3\/5\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/5);
    }
}

.u-3\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/6);
    }
}

.u-3\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/7);
    }
}

.u-3\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/8);
    }
}

.u-3\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/9);
    }
}

.u-3\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/10);
    }
}

.u-3\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/11);
    }
}

.u-3\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(3/12);
    }
}

.u-4\/4\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/4);
    }
}

.u-4\/5\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/5);
    }
}

.u-4\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/6);
    }
}

.u-4\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/7);
    }
}

.u-4\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/8);
    }
}

.u-4\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/9);
    }
}

.u-4\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/10);
    }
}

.u-4\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/11);
    }
}

.u-4\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(4/12);
    }
}

.u-5\/5\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/5);
    }
}

.u-5\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/6);
    }
}

.u-5\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/7);
    }
}

.u-5\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/8);
    }
}

.u-5\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/9);
    }
}

.u-5\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/10);
    }
}

.u-5\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/11);
    }
}

.u-5\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(5/12);
    }
}

.u-6\/6\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/6);
    }
}

.u-6\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/7);
    }
}

.u-6\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/8);
    }
}

.u-6\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/9);
    }
}

.u-6\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/10);
    }
}

.u-6\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/11);
    }
}

.u-6\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(6/12);
    }
}

.u-7\/7\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/7);
    }
}

.u-7\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/8);
    }
}

.u-7\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/9);
    }
}

.u-7\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/10);
    }
}

.u-7\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/11);
    }
}

.u-7\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(7/12);
    }
}

.u-8\/8\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(8/8);
    }
}

.u-8\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(8/9);
    }
}

.u-8\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(8/10);
    }
}

.u-8\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(8/11);
    }
}

.u-8\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(8/12);
    }
}

.u-9\/9\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(9/9);
    }
}

.u-9\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(9/10);
    }
}

.u-9\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(9/11);
    }
}

.u-9\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(9/12);
    }
}

.u-10\/10\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(10/10);
    }
}

.u-10\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(10/11);
    }
}

.u-10\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(10/12);
    }
}

.u-11\/11\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(11/11);
    }
}

.u-11\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(11/12);
    }
}

.u-12\/12\@from-enormous {
    @media (min-width: $from-enormous) {
        width: span(12/12);
    }
}

.u-1\/1\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/1);
    }
}

.u-1\/2\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/2);
    }
}

.u-1\/3\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/3);
    }
}

.u-1\/4\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/4);
    }
}

.u-1\/5\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/5);
    }
}

.u-1\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/6);
    }
}

.u-1\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/7);
    }
}

.u-1\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/8);
    }
}

.u-1\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/9);
    }
}

.u-1\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/10);
    }
}

.u-1\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/11);
    }
}

.u-1\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(1/12);
    }
}

.u-2\/2\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/2);
    }
}

.u-2\/3\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/3);
    }
}

.u-2\/4\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/4);
    }
}

.u-2\/5\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/5);
    }
}

.u-2\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/6);
    }
}

.u-2\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/7);
    }
}

.u-2\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/8);
    }
}

.u-2\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/9);
    }
}

.u-2\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/10);
    }
}

.u-2\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/11);
    }
}

.u-2\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(2/12);
    }
}

.u-3\/3\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/3);
    }
}

.u-3\/4\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/4);
    }
}

.u-3\/5\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/5);
    }
}

.u-3\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/6);
    }
}

.u-3\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/7);
    }
}

.u-3\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/8);
    }
}

.u-3\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/9);
    }
}

.u-3\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/10);
    }
}

.u-3\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/11);
    }
}

.u-3\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(3/12);
    }
}

.u-4\/4\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/4);
    }
}

.u-4\/5\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/5);
    }
}

.u-4\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/6);
    }
}

.u-4\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/7);
    }
}

.u-4\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/8);
    }
}

.u-4\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/9);
    }
}

.u-4\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/10);
    }
}

.u-4\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/11);
    }
}

.u-4\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(4/12);
    }
}

.u-5\/5\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/5);
    }
}

.u-5\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/6);
    }
}

.u-5\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/7);
    }
}

.u-5\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/8);
    }
}

.u-5\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/9);
    }
}

.u-5\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/10);
    }
}

.u-5\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/11);
    }
}

.u-5\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(5/12);
    }
}

.u-6\/6\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/6);
    }
}

.u-6\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/7);
    }
}

.u-6\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/8);
    }
}

.u-6\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/9);
    }
}

.u-6\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/10);
    }
}

.u-6\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/11);
    }
}

.u-6\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(6/12);
    }
}

.u-7\/7\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/7);
    }
}

.u-7\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/8);
    }
}

.u-7\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/9);
    }
}

.u-7\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/10);
    }
}

.u-7\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/11);
    }
}

.u-7\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(7/12);
    }
}

.u-8\/8\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(8/8);
    }
}

.u-8\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(8/9);
    }
}

.u-8\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(8/10);
    }
}

.u-8\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(8/11);
    }
}

.u-8\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(8/12);
    }
}

.u-9\/9\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(9/9);
    }
}

.u-9\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(9/10);
    }
}

.u-9\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(9/11);
    }
}

.u-9\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(9/12);
    }
}

.u-10\/10\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(10/10);
    }
}

.u-10\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(10/11);
    }
}

.u-10\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(10/12);
    }
}

.u-11\/11\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(11/11);
    }
}

.u-11\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(11/12);
    }
}

.u-12\/12\@from-gigantic {
    @media (min-width: $from-gigantic) {
        width: span(12/12);
    }
}

.u-1\/1\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/1);
    }
}

.u-1\/2\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/2);
    }
}

.u-1\/3\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/3);
    }
}

.u-1\/4\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/4);
    }
}

.u-1\/5\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/5);
    }
}

.u-1\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/6);
    }
}

.u-1\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/7);
    }
}

.u-1\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/8);
    }
}

.u-1\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/9);
    }
}

.u-1\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/10);
    }
}

.u-1\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/11);
    }
}

.u-1\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(1/12);
    }
}

.u-2\/2\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/2);
    }
}

.u-2\/3\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/3);
    }
}

.u-2\/4\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/4);
    }
}

.u-2\/5\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/5);
    }
}

.u-2\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/6);
    }
}

.u-2\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/7);
    }
}

.u-2\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/8);
    }
}

.u-2\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/9);
    }
}

.u-2\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/10);
    }
}

.u-2\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/11);
    }
}

.u-2\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(2/12);
    }
}

.u-3\/3\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/3);
    }
}

.u-3\/4\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/4);
    }
}

.u-3\/5\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/5);
    }
}

.u-3\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/6);
    }
}

.u-3\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/7);
    }
}

.u-3\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/8);
    }
}

.u-3\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/9);
    }
}

.u-3\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/10);
    }
}

.u-3\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/11);
    }
}

.u-3\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(3/12);
    }
}

.u-4\/4\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/4);
    }
}

.u-4\/5\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/5);
    }
}

.u-4\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/6);
    }
}

.u-4\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/7);
    }
}

.u-4\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/8);
    }
}

.u-4\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/9);
    }
}

.u-4\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/10);
    }
}

.u-4\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/11);
    }
}

.u-4\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(4/12);
    }
}

.u-5\/5\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/5);
    }
}

.u-5\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/6);
    }
}

.u-5\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/7);
    }
}

.u-5\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/8);
    }
}

.u-5\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/9);
    }
}

.u-5\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/10);
    }
}

.u-5\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/11);
    }
}

.u-5\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(5/12);
    }
}

.u-6\/6\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/6);
    }
}

.u-6\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/7);
    }
}

.u-6\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/8);
    }
}

.u-6\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/9);
    }
}

.u-6\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/10);
    }
}

.u-6\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/11);
    }
}

.u-6\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(6/12);
    }
}

.u-7\/7\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/7);
    }
}

.u-7\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/8);
    }
}

.u-7\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/9);
    }
}

.u-7\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/10);
    }
}

.u-7\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/11);
    }
}

.u-7\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(7/12);
    }
}

.u-8\/8\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(8/8);
    }
}

.u-8\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(8/9);
    }
}

.u-8\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(8/10);
    }
}

.u-8\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(8/11);
    }
}

.u-8\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(8/12);
    }
}

.u-9\/9\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(9/9);
    }
}

.u-9\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(9/10);
    }
}

.u-9\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(9/11);
    }
}

.u-9\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(9/12);
    }
}

.u-10\/10\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(10/10);
    }
}

.u-10\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(10/11);
    }
}

.u-10\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(10/12);
    }
}

.u-11\/11\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(11/11);
    }
}

.u-11\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(11/12);
    }
}

.u-12\/12\@from-colossal {
    @media (min-width: $from-colossal) {
        width: span(12/12);
    }
}

.u-center {
    display: block;
    margin: auto;
}